import { url_api } from './url'
import axios from 'axios';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
class Services {
    post(url = null, data = {}, options = {}) {
        return axios.post(url_api + url, data, options)
    }

    get(url, headers = {}) {
        return axios.get(url_api + url, headers);
    }

    delete(url, headers ={}) {
        console.log(headers)
        return axios.delete(url_api + url, headers);
    }
}

export default new Services();