import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
import Vuesax from 'vuesax'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import 'vuesax/dist/vuesax.css'

import { Icon }  from 'leaflet'
import router from './router'
import store from './store'
import VueAxios from 'vue-axios'
import axios from 'axios'
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
Vue.use(Vuesax, {
  // options here
})
Vue.use(VueAxios, axios)

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-popup', LPopup);

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
