<template>
  <div class="bodyColorLogin">
    <section class="sectionPrueba">
      <div class="container" style="border-radius: 20px">
        <div class="user signinBx">
          <div class="imgBx">
            <img src="../assets/signBackground1.jpg" alt="" />
          </div>
          <div class="formBx">
            <form>
              <h2>Sign In</h2>
              <vs-input
                primary
                type="email"
                icon-after
                v-model="loginCredentials.username"
                label-placeholder=""
              >
                <template #icon>
                  <b-icon-mailbox></b-icon-mailbox>
                </template>
              </vs-input>
              <vs-input
                primary
                icon-after
                type="password"
                v-model="loginCredentials.password"
                placeholder=""
              >
                <template #icon>
                  <b-icon-lock></b-icon-lock>
                </template>
              </vs-input>
              <vs-button block :active="active == 0" @click.prevent="login()">
                Login
              </vs-button>
              <!--<p class="signup" v-if="false">
                Don't have an account ? <a @click="toggleForm()"> Sign Up</a>
              </p>-->
            </form>
          </div>
        </div>
        <div class="user signupBx">
          <div class="formBx">
            <form action="" onsubmit="return false;">
              <h2>Create an account</h2>
              <input type="text" name="" placeholder="Username" />
              <input type="email" name="" placeholder="Email Address" />
              <input type="password" name="" placeholder="Create Password" />
              <input type="password" name="" placeholder="Confirm Password" />
              <input type="submit" name="" value="Sign Up" />
              <p class="signup">
                Already have an account ? <a @click="toggleForm()"> Sign In</a>
              </p>
            </form>
          </div>
          <div class="imgBx">
            <img src="../assets/background2.jpg" alt="" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Services from "../api/services";
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export default {
  data() {
    return {
      active: 0,
      loginCredentials: {
        username: null,
        password: null,
      },
    };
  },
  methods: {
    toggleForm() {
      const container = document.querySelector(".container");
      container.classList.toggle("active");
    },
    login() {
      if (!validateEmail(this.loginCredentials.username)) return;
      const loading = this.$vs.loading({
        text: "Loading...",
        type: "corners",
      });
      Services.post("login", this.loginCredentials)
        .then((response) => {
          this.$vs.notification({
            progress: "auto",
            color: "primary",
            position: null,
            title: "Succes !",
            text: `You have access granted 🦀`,
          });
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("user", true);
          this.$store.dispatch("user", true);
          this.$router.push({ name: "admin" });
        })
        .catch(() => {
          this.$vs.notification({
            progress: "auto",
            color: "danger",
            position: null,
            title: "An error has occurred!",
            text: `Wrong username or password. Please check your details and try again 🦑`,
          });
          localStorage.setItem("user", false);
          this.$store.dispatch("user", false);
        })
        .finally(() => loading.close());
    },
  },
  beforeCreate() {
    if (this.$store.state.user) {
      this.$router.push({ name: "admin" });
    }
  },
};
</script>

<style>
@import "../assets/styles/Login.css";
</style>
