<template>
  <div class="sectionAdmin">
    <div>
      <vs-navbar class="sticky" center-collapsed v-model="active">
        <template #left>
          <img style="cursor: pointer" @click="activeSidebar = !activeSidebar" src="../assets/logoEsteban.jpg"
            width="90px" height="90px" alt="" />
        </template>
        <b>{{ capitalizeFirstLetter }}</b>
        <template #right>
          <vs-avatar size="35" badge badge-color="success">
            <img src="../assets/administracion.png" alt="" />
          </vs-avatar>
        </template>
      </vs-navbar>
      <vs-sidebar class="sticky" v-model="active" :open.sync="activeSidebar">
        <template #logo>
          <!-- ...img logo -->
        </template>
        <vs-sidebar-item id="home">
          <template #icon>
            <b-icon-shop></b-icon-shop>
          </template>
          Home
        </vs-sidebar-item>
        <vs-sidebar-item id="brand">
          <template #icon>
            <b-icon-tag-fill></b-icon-tag-fill>
          </template>
          Brands
        </vs-sidebar-item>
        <vs-sidebar-item id="requests">
          <template #icon>
            <b-icon-chat></b-icon-chat>
          </template>
          Requests
        </vs-sidebar-item>
        <vs-sidebar-item id="users">
          <template #icon>
            <b-icon-person></b-icon-person>
          </template>
          Users
        </vs-sidebar-item>
        <template #footer>
          <vs-button block @click="logout()">
            <i class="bx bxs-paint-roll"></i> Logout
          </vs-button>
        </template>
      </vs-sidebar>
    </div>
    <!--HOME -->
    <section v-show="active == 'home'" style="margin-top: 60px; overflow: hidden">
      <!--DIALOG NEW CAR -->
      <vs-dialog ref="contentAddCatalog" prevent-close blur overflow-hidden full-screen v-model="activeDialogNewCar">
        <template #header>
          <h3 class="not-margin">Add a new <b>car</b></h3>
        </template>
        <br />
        <br />
        <div class="con-form">
          <vs-row :w="12">
            <vs-col :w="6">
              <vs-input label="Name: " v-model="formAddCar.name" placeholder="Name">
                <template #icon>
                  <b-icon-tag></b-icon-tag>
                </template>
              </vs-input>
            </vs-col>
            <vs-col :w="6">
              <vs-select filter :key="count" id="selectBrand" label="Brand" @change="changeSelectBrand"
                v-show="brandsCatalog.length > 0" style="height: 10px" placeholder="Select" v-model="formAddCar.brand">
                <vs-option v-show="brandsCatalog.length > 0" v-for="brand in brandsCatalog" :key="brand.name"
                  :label="brand.name" :value="brand.name">
                  {{ brand.name }}
                </vs-option>
              </vs-select>
            </vs-col>
          </vs-row>
          <br />
          <vs-row :w="12">
            <vs-col :w="6">
              <vs-input label="Sales Price: " v-model="formAddCar.salesPrice" type="number" placeholder="Sales Price">
                <template #icon>
                  <b-icon-currency-dollar></b-icon-currency-dollar>
                </template>
              </vs-input>
            </vs-col>
          </vs-row>
          <br />
          <vs-input label="Description: " v-model="formAddCar.description" placeholder="Description">
            <template #icon>
              <b-icon-clipboard></b-icon-clipboard>
            </template>
          </vs-input>
          <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center">
            <vue-upload-multiple-image @upload-success="uploadImageSuccess" @before-remove="beforeRemove"
              @edit-image="editImage" :data-images="images" idUpload="myIdUpload" editUpload="myIdEdit">
            </vue-upload-multiple-image>
          </div>
          <vs-button block @click="addCarButton()">
            <i class="bx bxs-paint-roll"></i> Add car
          </vs-button>
        </div>
      </vs-dialog>

      <!--DIALOG INFO CAR-->
      <vs-dialog prevent-close blur overflow-hidden full-screen v-model="activeDialogInfoCar">
        <template #header>
          <h2 class="not-margin"><b>Information car</b></h2>
        </template>
        <vs-card-group>
          <vs-card :key="card" v-for="card in carSelected.photos">
            <template #title> </template>
            <template #img>
              <img @click="showImageInRoute(url_image + 'static/' + card)" :src="url_image + 'static/' + card" alt="" />
            </template>
            <template #text> </template>
            <template #interactions> </template>
          </vs-card>
        </vs-card-group>
        <br />
        <div style="margin-left: 50px; margin-right: 50px">
          <h3 id="header-title" style="font-size: 20px">{{ carSelected.name }}</h3>
          <h2 id="header-title" style="font-size: 15px; margin-bottom: 10px">
            {{ carSelected.brand }}
            <br />
            ${{ carSelected.salesPrice }}
          </h2>
          <hr />
          <span style="margin-top: 10px">{{ carSelected.description }}</span>
        </div>
      </vs-dialog>

      <!--DIALOG DELETE CAR-->
      <vs-dialog prevent-close width="550px" blur not-center v-model="dialogDeleteCar">
        <template #header>
          <h3 class="not-margin"><b>Delete Car</b></h3>
        </template>

        <div class="con-content">
          <p>Are you sure to delete car?</p>
        </div>

        <template #footer>
          <div class="con-footer">
            <vs-row>
              <vs-col w="1">
                <vs-button @click="confirmDeleteCar()"> Ok </vs-button>
              </vs-col>
              <vs-col w="1">
                <vs-button @click="dialogDeleteCar = false" danger> Cancel </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </template>
      </vs-dialog>
      <vs-row :w="12">
        <vs-col lg="4" sm="4" xs="12">
          <vs-input v-model="valueSearchCar" @change="changeValueSearch" type="search" primary
            style="margin-left: 10px; margin-top: 10px" placeholder="">
            <template #icon v-show="active == 'home'">
              <b-icon-search></b-icon-search>
            </template>
          </vs-input>
        </vs-col>
        <vs-col lg="4" sm="4" xs="12">
          <div class="center">
            <vs-select filter :key="count" id="selectBrand" @change="changeSelect" v-show="brandsCatalog.length > 0"
              style="margin-left: 10px; margin-top: 10px" placeholder="Select" v-model="valueSelectBrand">
              <vs-option v-show="brandsCatalog.length > 0" v-for="brand in brandsCatalog" :key="brand.name"
                :label="brand.name" :value="brand.name">
                {{ brand.name }}
              </vs-option>
            </vs-select>
          </div>
        </vs-col>
        <vs-col lg="4" sm="4" xs="12">
          <vs-button @click="
            () => {
              showDialogNewCar();
            }
          " style="margin-left: 10px; margin-top: 10px; width: 95%">
            <b-icon-plus-lg></b-icon-plus-lg>&nbsp;&nbsp; Add new car
          </vs-button>
        </vs-col>
      </vs-row>

      <div>
        <vs-row>
          <vs-col :key="data._id['$oid']" v-for="data in carsCatalogCopy" vs-type="flex" vs-justify="center"
            vs-align="center" lg="2" sm="3" xs="6">
            <!---->
            <vs-card type="2" style="padding: 5px">
              <template #title>
                <h3>{{ data.name }}</h3>
              </template>
              <template #img>
                <img style="width: 100%" :src="url_image + 'static/' + data.photos[0]" alt="" />
              </template>
              <template #text>
                <p>{{ data.brand }}</p>
              </template>
              <template #interactions>
                <vs-button @click="deleteCatalog(data)" danger icon>
                  <b-icon-trash></b-icon-trash>
                </vs-button>
                <vs-button @click="infoCatalog(data)" class="btn-chat" shadow primary>
                  <b-icon-info-lg></b-icon-info-lg>
                </vs-button>
              </template>
            </vs-card>
          </vs-col>
        </vs-row>
      </div>
    </section>

    <!--BRAND -->
    <section v-show="active == 'brand'"
      style="margin-top: 60px; margin-left: 10px; margin-right: 10px; overflow: hidden">
      <br />
      <br />
      <vs-row :w="12">
        <vs-col :w="3" style="margin-top: 5px">
          <vs-input label="Name" v-model="brandName" placeholder="Name" />
        </vs-col>
        <vs-col :w="3">
          <vs-button @click="createBrand()" primary>
            <b-icon-plus-lg></b-icon-plus-lg>&nbsp;&nbsp; Create brand
          </vs-button>
        </vs-col>
      </vs-row>
      <br />
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th> Name </vs-th>
            <vs-th> Actions </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="i" v-for="(tr, i) in $vs.getPage(brandsCatalog, page, max)" :data="tr">
            <vs-td>
              {{ tr.name }}
            </vs-td>
            <vs-td>
              <vs-row :w="12">
                <vs-col :w="2">
                  <vs-button @click="modalEditBrand(tr)" icon color="primary">
                    <b-icon-tools></b-icon-tools>
                  </vs-button>
                </vs-col>
                <vs-col :w="2">
                  <vs-button icon danger>
                    <b-icon-trash @click="modalDeleteBrand(tr)"></b-icon-trash>
                  </vs-button>
                </vs-col>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="page" :length="$vs.getLength(brandsCatalog, max)" />
        </template>
      </vs-table>

      <vs-dialog blur prevent-close v-model="showModalEditBrand">
        <template #header>
          <h4 class="not-margin">
            <b>Edit brand</b>
          </h4>
        </template>
        <div class="con-form">
          <vs-input v-model="brandToEdit.name" placeholder="Name">
            <template #icon>
              <b-icon-tag></b-icon-tag>
            </template>
          </vs-input>
          <vs-button @click="editBrand()" primary> Edit brand </vs-button>
        </div>
      </vs-dialog>

      <vs-dialog blur prevent-close v-model="showModalEditRequest">
        <template #header>
          <h4 class="not-margin">
            <b>Edit request</b>
          </h4>
        </template>

        <vs-select label="Viewed" v-model="requestToEdit.requestShowed">
          <vs-option label="Yes" value="true"> Yes </vs-option>
          <vs-option label="No" value="false"> No </vs-option>
        </vs-select>
        <vs-button @click="editRequest()" primary> Edit brand </vs-button>
      </vs-dialog>

      <vs-dialog blur prevent-close width="550px" not-center v-model="showModalDeleteBrand">
        <template #header>
          <h3 class="not-margin"><b>Delete Request</b></h3>
        </template>

        <div class="con-content">
          <p>Are you sure to delete request?</p>
        </div>

        <template #footer>
          <div class="con-footer">
            <vs-row>
              <vs-col :w="1">
                <vs-button @click="confirmDeleteBrand()"> Ok </vs-button>
              </vs-col>
              <vs-col :w="1">
                <vs-button @click="showModalDeleteBrand = false" danger>
                  Cancel
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </template>
      </vs-dialog>
    </section>

    <!--NOTIFICATIONS -->
    <section v-show="active == 'requests'"
      style="margin-top: 60px; margin-left: 10px; margin-right: 10px; overflow: hidden">
      <vs-dialog blur prevent-close width="550px" not-center v-model="showModalDeleteRequest">
        <template #header>
          <h3 class="not-margin"><b>Delete Brand</b></h3>
        </template>

        <div class="con-content">
          <p>Are you sure to delete brand?</p>
        </div>

        <template #footer>
          <div class="con-footer">
            <vs-row>
              <vs-col :w="1">
                <vs-button @click="confirmDeleteRequest()"> Ok </vs-button>
              </vs-col>
              <vs-col :w="1">
                <vs-button @click="showModalDeleteRequest = false" danger>
                  Cancel
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </template>
      </vs-dialog>
      <vs-button @click="
        () => {
          showAllRequests();
          countTableRequests += 1;
        }
      ">
        <b-icon-arrow-repeat></b-icon-arrow-repeat>&nbsp;&nbsp; Refresh table
      </vs-button>
      <vs-table :key="countTableRequests">
        <template #thead>
          <vs-tr>
            <vs-th> Name </vs-th>
            <vs-th> Lastname </vs-th>
            <vs-th> Email </vs-th>
            <vs-th> Mob. phone </vs-th>
            <vs-th> Viewed </vs-th>
            <vs-th> Req. date </vs-th>
            <vs-th> Actions </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="i" v-for="(tr, i) in $vs.getPage(requests, pageRequest, maxRequest)" :data="tr">
            <vs-td>
              {{ tr.firstNameApplicant }}
            </vs-td>
            <vs-td>
              {{ tr.lastNameApplicant }}
            </vs-td>
            <vs-td>
              {{ tr.emailApplicant }}
            </vs-td>
            <vs-td>
              {{ tr.mobilePhoneApplicant }}
            </vs-td>
            <vs-td>
              <span v-if="tr.requestShowed == 'false' || tr.requestShowed == false">
                <vs-avatar danger size="30">
                  <template #text> No </template>
                </vs-avatar>
              </span>
              <span v-if="tr.requestShowed == 'true' || tr.requestShowed == true">
                <vs-avatar primary size="30">
                  <template #text> Yes </template>
                </vs-avatar>
              </span>
            </vs-td>
            <vs-td>
              {{ tr.actualDate }}
            </vs-td>
            <vs-td>
              <vs-row :w="12">
                <vs-col :w="6">
                  <vs-button @click="modalEditRequest(tr)" icon color="primary">
                    <b-icon-tools></b-icon-tools>
                  </vs-button>
                </vs-col>

                <vs-col :w="6">
                  <vs-button icon danger>
                    <b-icon-trash @click="modalDeleteRequest(tr)"></b-icon-trash>
                  </vs-button>
                </vs-col>
              </vs-row>
              <vs-button icon :color="'#6800F5'">
                <b-icon-eye @click="showInfoRequest(tr)"></b-icon-eye>
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="pageRequest" :length="$vs.getLength(requests, maxRequest)" />
        </template>
      </vs-table>

      <br />
      <div v-show="showInformationRequest">
        <h2 class="not-margin"><b>Information car</b></h2>
        <br />
        <vs-card-group>
          <vs-card :key="card" v-for="card in formToSendApplicant.photos">
            <template #title> </template>
            <template #img>
              <img @click="showImageInRoute(url_image + 'static/' + card)" :src="url_image + 'static/' + card" alt="" />
            </template>
            <template #text> </template>
            <template #interactions> </template>
          </vs-card>
        </vs-card-group>
        <div>
          <h3 id="header-title" style="font-size: 20px">
            {{ formToSendApplicant.name }}
          </h3>
          <h2 id="header-title" style="font-size: 15px; margin-bottom: 10px">
            {{ formToSendApplicant.brand }}
            <br />
            ${{ formToSendApplicant.salesPrice }}
          </h2>
          <hr />
          <span style="margin-top: 10px">{{ formToSendApplicant.description }}</span>
        </div>
      </div>
      <br />
      <section style="background: #d8d8d8; padding: 5px; border-radius: 10px" v-show="showInformationRequest">
        <!--<template #title>Apply For Credit </template>-->
        <vs-row>
          <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
            <b>Applicant Information</b>
            <br />
            <br />
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
            <vs-input color="primary" label="First Name: *" readonly v-model="formToSendApplicant.firstNameApplicant"
              placeholder="">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
            <vs-input color="primary" label="Middle Name:" readonly v-model="formToSendApplicant.middleNameApplicant"
              placeholder="" />
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
            <vs-input readonly color="primary" label="Last Name: *" v-model="formToSendApplicant.lastNameApplicant"
              placeholder="">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
            <vs-input readonly color="primary" label="Address 1: *" v-model="formToSendApplicant.address1Applicant"
              placeholder="">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
            <vs-input readonly color="primary" label="Address 2:" v-model="formToSendApplicant.address2Applicant"
              placeholder="">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
            <vs-input readonly color="primary" label="City: *" v-model="formToSendApplicant.cityApplicant"
              placeholder="">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
            <vs-input readonly color="primary" label="State: *" v-model="formToSendApplicant.stateApplicant"
              placeholder="" />
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
            <vs-input readonly color="primary" label="ZIP: *" v-model="formToSendApplicant.zipApplicant" placeholder="">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input readonly color="primary" label="Social security: *"
              v-model="formToSendApplicant.socialSecurityApplicant" placeholder="">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input readonly type="text" v-model="formToSendApplicant.dateOfBirthApplicant" label="Date of birth: *">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input readonly type="text" v-model="formToSendApplicant.driverLicenseNumberApplicant"
              label="Drivers license number:" />
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input readonly type="text" v-model="formToSendApplicant.driverLicenseStateApplicant"
              label="Drivers license state:" />
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input readonly type="text" v-model="formToSendApplicant.driverLicenseExpApplicant"
              label="Drivers license exp:" />
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input readonly type="number" v-model="formToSendApplicant.mobilePhoneApplicant" label="Mobile phone: *">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input readonly type="number" v-model="formToSendApplicant.homePhoneApplicant" label="Home Phone:" />
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input readonly type="text" v-model="formToSendApplicant.emailApplicant" label="Email: *">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
            Time at Residence:
            <br />
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input readonly type="text" v-model="formToSendApplicant.yearsTimeAtResidenceApplicant" label="Years: *">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input readonly type="text" v-model="formToSendApplicant.monthsTimeAtResidenceApplicant"
              label="Months: *">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input readonly type="text" v-model="formToSendApplicant.typeResidenceApplicant"
              label="Residence Type: *">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input readonly type="text" v-model="formToSendApplicant.rentMortgageApplicant" label="Rent/Mortgage: *">
            </vs-input>
          </vs-col>
          <vs-col :lg="12" :sm="12" :xs="12">
            <br />
            <b style="margin-bottom: 10px">Applicant Employment Information</b>
            <br />
            <br />
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input type="text" readonly v-model="formToSendApplicant.employerApplicant" label="Employer: *">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input type="text" readonly v-model="formToSendApplicant.monthlyIncomeApplicant"
              label="Monthly Income: *">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
            <vs-input type="text" readonly v-model="formToSendApplicant.ocupationApplicant" label="Occupation: *">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input type="text" readonly v-model="formToSendApplicant.address1OcupationApplicant" label="Adress 1:" />
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input type="text" readonly v-model="formToSendApplicant.address2OcupationApplicant" label="Adress 2:" />
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
            <vs-input color="primary" label="City: *" readonly v-model="formToSendApplicant.cityOcupationApplicant"
              placeholder="">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
            <vs-input color="primary" label="State: *" readonly v-model="formToSendApplicant.stateOcupationApplicant"
              placeholder="">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
            <vs-input readonly color="primary" label="ZIP: *" v-model="formToSendApplicant.zipOcupationApplicant"
              placeholder="">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
            <vs-input readonly color="primary" type="number" label="Work phone: *"
              v-model="formToSendApplicant.workPhoneApplicant" placeholder="">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
            Time on Job:
            <br />
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input readonly color="primary" type="number" label="Years: *"
              v-model="formToSendApplicant.yearsTimeOnJobApplicant" placeholder="">
            </vs-input>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
            <vs-input readonly color="primary" type="number" label="Months: *"
              v-model="formToSendApplicant.monthsTimeOnJobApplicant" placeholder="">
            </vs-input>
          </vs-col>
          <vs-col :lg="12" :sm="12" :xs="12">
            <br />
            <b style="margin-bottom: 10px">Do you have a co-buyer?</b>
            <br />
            <vs-button @click="activeCollapseCoBuyer()">
              {{ activeButtonCollapseCoBuyer ? "Hide co-buyer" : "Show co-buyer" }}
            </vs-button>
          </vs-col>
          <vs-row v-show="activeButtonCollapseCoBuyer">
            <vs-col v-show="activeButtonCollapseCoBuyer" :lg="12" :sm="12" :xs="12">
              <br />
              <b style="margin-bottom: 10px">Co-Buyer Information</b>
              <br />
              <br />
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
              <vs-input readonly color="primary" label="First Name: *" v-show="activeButtonCollapseCoBuyer"
                v-model="formToSendApplicant.firstNameCoBuyer" placeholder="">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
              <vs-input readonly color="primary" v-show="activeButtonCollapseCoBuyer" label="Middle Name:"
                v-model="formToSendApplicant.middleNameCoBuyer" placeholder="" />
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
              <vs-input readonly color="primary" label="Last Name: *" v-show="activeButtonCollapseCoBuyer"
                v-model="formToSendApplicant.lastNameCoBuyer" placeholder="">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" color="primary" label="Address 1: *"
                v-model="formToSendApplicant.address1CoBuyer" placeholder="">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" color="primary" label="Address 2:"
                v-model="formToSendApplicant.address2CoBuyer" placeholder="" />
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" color="primary" label="City: *"
                v-model="formToSendApplicant.cityCoBuyer" placeholder="">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" color="primary" label="State: *"
                v-model="formToSendApplicant.stateCoBuyer" placeholder="">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" color="primary" label="ZIP: *"
                v-model="formToSendApplicant.zipCoBuyer" placeholder="">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" color="primary" label="Social security: *"
                v-model="formToSendApplicant.socialSecurityCoBuyer" placeholder="">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="text"
                v-model="formToSendApplicant.dateOfBirthCoBuyer" label="Date of birth: *">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="text"
                v-model="formToSendApplicant.driverLicenseNumberCoBuyer" label="Drivers license number:" />
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="text"
                v-model="formToSendApplicant.driverLicenseStateCoBuyer" label="Drivers license state:" />
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="text"
                v-model="formToSendApplicant.driverLicenseExpCoBuyer" label="Drivers license exp:" />
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="number"
                v-model="formToSendApplicant.mobilePhoneCoBuyer" label="Mobile phone: *">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="number"
                v-model="formToSendApplicant.homePhoneCoBuyer" label="Home Phone:" />
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="text"
                v-model="formToSendApplicant.emailCoBuyer" label="Email: *">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
              <span v-show="activeButtonCollapseCoBuyer">Time at Residence:</span>
              <br />
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="text"
                v-model="formToSendApplicant.yearsTimeAtResidenceCoBuyer" label="Years: *">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="text"
                v-model="formToSendApplicant.monthsTimeAtResidenceCoBuyer" label="Months: *">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="text"
                v-model="formToSendApplicant.typeResidenceCoBuyer" label="Residence Type: *">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="text"
                v-model="formToSendApplicant.rentMortgageCoBuyer" label="Rent/Mortgage: *">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" :lg="12" :sm="12" :xs="12">
              <br />
              <b style="margin-bottom: 10px">Co-Buyer Employment Information</b>
              <br />
              <br />
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="text"
                v-model="formToSendApplicant.employerCoBuyer" label="Employer: *">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="text"
                v-model="formToSendApplicant.monthlyIncomeCoBuyer" label="Monthly Income: *">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="text"
                v-model="formToSendApplicant.ocupationCoBuyer" label="Occupation: *">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="text"
                v-model="formToSendApplicant.address1OcupationCoBuyer" label="Adress 1:" />
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" type="text"
                v-model="formToSendApplicant.address2OcupationCoBuyer" label="Adress 2:" />
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" color="primary" label="City: *"
                v-model="formToSendApplicant.cityOcupationCoBuyer" placeholder="">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" color="primary" label="State: *"
                v-model="formToSendApplicant.stateOcupationCoBuyer" placeholder="">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" color="primary" label="ZIP: *"
                v-model="formToSendApplicant.zipOcupationCoBuyer" placeholder="">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" color="primary" type="number"
                label="Work phone: *" v-model="formToSendApplicant.workPhoneCoBuyer" placeholder="">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
              <span v-show="activeButtonCollapseCoBuyer">Time on Job:</span>
              <br />
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" color="primary" type="number" label="Years: *"
                v-model="formToSendApplicant.yearsTimeOnJobCoBuyer" placeholder="">
              </vs-input>
            </vs-col>
            <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
              <vs-input readonly v-show="activeButtonCollapseCoBuyer" color="primary" type="number" label="Months: *"
                v-model="formToSendApplicant.monthsTimeOnJobCoBuyer" placeholder="">
              </vs-input>
            </vs-col>
          </vs-row>
          <br />
          <br />
          <vs-col :lg="12" :sm="12" :xs="12">
            <br />
            <b style="margin-bottom: 10px">Additional Comments</b>
          </vs-col>
          <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
            <vs-input readonly color="primary" type="text" v-model="formToSendApplicant.comments" placeholder="" />
          </vs-col>
          <vs-col style="margin-top: 15px" :lg="12" :sm="12" :xs="12">
            <label><input readonly v-model="formToSendApplicant.acknowledgmentAndConsent" type="checkbox"
                class="rounded-checkbox" id="cbox1" />
              ACKNOWLEDGMENT AND CONSENT: <br />I certify that the above information is complete and accurate to the
              best of my knowledge. Creditors receiving this application will retain the application whether or not it
              is approved. Creditors may rely on this application in deciding whether to grant the requested credit.
              False statements may subject me to criminal penalties. I authorize the creditors to obtain credit reports
              about me on an ongoing basis during this credit transaction and to check my credit and employment history
              on an ongoing basis during the term of the credit transaction. If this application is approved, I
              authorize the creditor to give credit information about me to its affiliates.</label><br />
          </vs-col>
          <br />
          <br />
          <vs-col style="margin-top: 15px" :lg="12" :sm="12" :xs="12">
            <label><input readonly v-model="formToSendApplicant.acknowledgmentAndConsent2" type="checkbox"
                class="rounded-checkbox" id="cbox1" />
              ACKNOWLEDGMENT AND CONSENT:<br />
              I hereby consent to receive text messages or phone calls from or on behalf
              of the dealer or their employees to the mobile phone number I provided
              above. By opting in, I understand that message and data rates may apply.
              This acknowledgement constitutes my written consent to receive text messages
              to my cell phone and phone calls, including communications sent using an
              auto-dialer or pre-recorded message. You may withdraw your consent at any
              time by texting "STOP".</label><br />
          </vs-col>
        </vs-row>
        <br />
        <br />
      </section>
      <br />
    </section>
    <section v-show="active == 'users'"
      style="margin-top: 60px; overflow: hidden; margin-left: 10px; margin-right: 10px">
      <vs-button @click="
        () => {
          showDialogNewUser();
          countTableRequests += 1;
        }
      ">
        <b-icon-plus-lg></b-icon-plus-lg>&nbsp;&nbsp;Add new user
      </vs-button>
      <vs-table :key="countTableUser">
        <template #thead>
          <vs-tr>
            <vs-th> Name </vs-th>
            <vs-th> Actions </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :key="i" v-for="(tr, i) in $vs.getPage(users, pageUsers, maxUsers)" :data="tr">
            <vs-td>
              {{ tr.username }}
            </vs-td>
            <vs-td>
              <vs-row :w="12">
                <vs-col :w="6">
                  <vs-button @click="modalEditUser(tr)" icon color="primary">
                    <b-icon-tools></b-icon-tools>
                  </vs-button>
                </vs-col>

                <vs-col :w="6">
                  <vs-button icon danger>
                    <b-icon-trash @click="modalDeleteUser(tr)"></b-icon-trash>
                  </vs-button>
                </vs-col>
              </vs-row>
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-model="pageUsers" :length="$vs.getLength(users, maxUsers)" />
        </template>
      </vs-table>

      <vs-dialog blur prevent-close v-model="showModalEditUser">
        <template #header>
          <h4 class="not-margin">
            <b>Edit user</b>
          </h4>
        </template>
        <vs-input color="primary" type="password" label="Password" v-model="userToEdit.password" placeholder="" />
        <vs-button @click="editPassword()" primary> Edit password </vs-button>
      </vs-dialog>

      <vs-dialog blur prevent-close width="550px" not-center v-model="showModalDeleteUser">
        <template #header>
          <h3 class="not-margin"><b>Delete User</b></h3>
        </template>

        <div class="con-content">
          <p>Are you sure to delete user?</p>
        </div>

        <template #footer>
          <div class="con-footer">
            <vs-row>
              <vs-col :w="1">
                <vs-button @click="confirmDeleteUser()"> Ok </vs-button>
              </vs-col>
              <vs-col :w="1">
                <vs-button @click="showModalDeleteUser = false" danger>
                  Cancel
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
        </template>
      </vs-dialog>

      <vs-dialog prevent-close blur overflow-hidden full-screen v-model="activeDialogNewUser">
        <template #header>
          <h3 class="not-margin">Add a new <b>user</b></h3>
        </template>
        <br />
        <br />
        <div class="con-form">
          <vs-row :w="12">
            <vs-col :w="6">
              <vs-input label="Username: " v-model="formAddUser.username" placeholder="Username">
                <template #icon>
                  <b-icon-tag></b-icon-tag>
                </template>
              </vs-input>
            </vs-col>
            <vs-col :w="6">
              <vs-input label="Password: " type="password" v-model="formAddUser.password" placeholder="Password">
                <template #icon>
                  <b-icon-eyeglasses></b-icon-eyeglasses>
                </template>
              </vs-input>
            </vs-col>
          </vs-row>
          <br />
          <vs-button block @click="addUserButton()">
            <i class="bx bxs-paint-roll"></i> Add user
          </vs-button>
        </div>
      </vs-dialog>
    </section>
  </div>
</template>
<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";
import services from "../api/services";
import { url_imagen } from "../api/url";

function isEmpty(str) {
  return !str || str.length === 0;
}
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function filterByValue(array, value) {
  return array.filter(
    (data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
}

export default {
  components: {
    VueUploadMultipleImage,
  },
  data: () => ({
    userToEdit: {
      name: "",
      _id: "",
    },
    showModalEditUser: false,
    pageUsers: 1,
    maxUsers: 3,
    users: [],
    showInformationRequest: false,
    countSelect: 0,
    formToSendApplicant: {
      acknowledgmentAndConsent: false,
      acknowledgmentAndConsent2: false,
      firstNameApplicant: "",
      middleNameApplicant: "",
      lastNameApplicant: "",
      address1Applicant: "",
      address2Applicant: "",
      cityApplicant: "",
      stateApplicant: "",
      zipApplicant: "",
      socialSecurityApplicant: "",
      dateOfBirthApplicant: "",
      driverLicenseNumberApplicant: "",
      driverLicenseStateApplicant: "",
      driverLicenseExpApplicant: "",
      mobilePhoneApplicant: "",
      homePhoneApplicant: "",
      emailApplicant: "",
      yearsTimeAtResidenceApplicant: "",
      monthsTimeAtResidenceApplicant: "",
      typeResidenceApplicant: "",
      rentMortgageApplicant: "",
      employerApplicant: "",
      monthlyIncomeApplicant: "",
      ocupationApplicant: "",
      address1OcupationApplicant: "",
      address2OcupationApplicant: "",
      cityOcupationApplicant: "",
      stateOcupationApplicant: "",
      zipOcupationApplicant: "",
      workPhoneApplicant: "",
      yearsTimeOnJobApplicant: "",
      monthsTimeOnJobApplicant: "",
      firstNameCoBuyer: "",
      middleNameCoBuyer: "",
      lastNameCoBuyer: "",
      address1CoBuyer: "",
      address2CoBuyer: "",
      cityCoBuyer: "",
      stateCoBuyer: "",
      zipCoBuyer: "",
      socialSecurityCoBuyer: "",
      dateOfBirthCoBuyer: "",
      driverLicenseNumberCoBuyer: "",
      driverLicenseStateCoBuyer: "",
      driverLicenseExpCoBuyer: "",
      mobilePhoneCoBuyer: "",
      homePhoneCoBuyer: "",
      emailCoBuyer: "",
      yearsTimeAtResidenceCoBuyer: "",
      monthsTimeAtResidenceCoBuyer: "",
      typeResidenceCoBuyer: "",
      rentMortgageCoBuyer: "",
      employerCoBuyer: "",
      monthlyIncomeCoBuyer: "",
      ocupationCoBuyer: "",
      address1OcupationCoBuyer: "",
      address2OcupationCoBuyer: "",
      cityOcupationCoBuyer: "",
      stateOcupationCoBuyer: "",
      zipOcupationCoBuyer: "",
      workPhoneCoBuyer: "",
      yearsTimeOnJobCoBuyer: "",
      monthsTimeOnJobCoBuyer: "",
      comments: "",
    },

    activeButtonCollapseCoBuyer: false,
    countTableRequests: 0,
    pageRequest: 1,
    maxRequest: 20,
    requests: [],

    valueSearchCar: "",
    valueSelectBrand: "Disabled",
    showModalDeleteBrand: false,
    showModalEditBrand: false,
    showModalDeleteRequest: false,
    showModalDeleteUser: false,
    userToDelete: "",
    showModalEditRequest: false,
    brandToEdit: {
      name: "",
      _id: "",
    },
    requestToEdit: {
      requestShowed: "",
      _id: "",
    },
    page: 1,
    max: 10,
    count: 0,
    countTableUser: 0,
    brandName: "",
    users: [],
    brandsCatalog: [],
    carToDelete: "",
    requToDelete: "",
    brandToDelete: "",
    dialogDeleteCar: false,
    carSelected: {},
    activeDialogInfoCar: false,
    url_image: url_imagen,
    carsCatalog: [],
    carsCatalogCopy: [],
    formAddUser: {
      username: "",
      password: "",
    },
    formAddCar: {
      name: "",
      brand: "",
      description: "",
      salesPrice: "",
    },
    fileImages: [],
    images: [],
    activeDialogNewCar: false,
    activeDialogNewUser: false,
    active: "home",
    activeSidebar: false,
    percent: 0,
  }),
  watch: {
    activeDialogNewCar() {
      if (this.activeDialogNewCar) {
        setTimeout(function () {
          var cols = document.getElementsByClassName("vs-input");
          for (let i = 0; i < cols.length; i++) {
            cols[i].style.width = "99%";
          }

          var colsOv = document.getElementsByClassName("image-overlay[data-v-10e59822]");
          for (let i = 0; i < colsOv.length; i++) {
            colsOv[i].style.background = "green";
          }
        }, 100);
      }
    },
    activeDialogNewUser() {
      if (this.activeDialogNewUser) {
        setTimeout(function () {
          var cols = document.getElementsByClassName("vs-input");
          for (let i = 0; i < cols.length; i++) {
            cols[i].style.width = "99%";
          }
        }, 100);
      }
    },
  },
  computed: {
    capitalizeFirstLetter() {
      const capitalized = this.active.charAt(0).toUpperCase() + this.active.slice(1);
      return capitalized;
    },
  },
  methods: {
    addUserButton() {
      if (isEmpty(this.formAddUser.username) || isEmpty(this.formAddUser.password)) {
        this.$vs.notification({
          progress: "auto",
          color: "danger",
          position: null,
          title: "An error has occurred!",
          text: `All fields are required 🎫`,
        });
      } else {
        const loading = this.$vs.loading({
          text: "Loading...",
          type: "corners",
        });
        let formData = new FormData();
        formData.append("username", this.formAddUser.username);
        formData.append("password", this.formAddUser.password);
        services
          .post("register_user", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$vs.notification({
              progress: "auto",
              color: "primary",
              position: null,
              title: "Succes !",
              text: `User added 🎟`,
            });
            this.formAddUser.username = "";
            this.formAddUser.password = "";
            this.showAllUsers();
          })
          .finally(() => loading.close());
      }
    },
    editPassword() {
      let formData = new FormData();
      formData.append("password", this.userToEdit.password);
      formData.append("_id", this.userToEdit._id["$oid"]);
      services
        .post("edit_user", formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(() => {
          this.showAllUsers();
          this.$vs.notification({
            progress: "auto",
            color: "primary",
            position: null,
            title: "Succes !",
            text: `User edited 🎟`,
          });
        })
        .finally(() => loading.close());
    },
    modalEditUser(data) {
      this.userToEdit = data;
      this.showModalEditUser = true;
    },
    logout() {
      localStorage.setItem("token", null);
      localStorage.setItem("user", false);
      this.$router.push({ path: "/login" });
    },
    changeSelectApplicant() {
      this.countSelect += 1;
      setTimeout(function () {
        var cols = document.getElementsByClassName("vs-select__input");
        for (let i = 0; i < cols.length; i++) {
          cols[i].style["color"] = "black";
          cols[i].style["background"] = "white";
          cols[i].style["min-height"] = "30%";
        }
      }, 300);
    },
    activeCollapseCoBuyer() {
      this.activeButtonCollapseCoBuyer = !this.activeButtonCollapseCoBuyer;
      setTimeout(function () {
        var cols2 = document.getElementsByClassName("vs-input");
        for (let i = 0; i < cols2.length; i++) {
          cols2[i].style["color"] = "black";
          cols2[i].style["background"] = "white";
          cols2[i].style.width = "99%";
        }
        var cols = document.getElementsByClassName("vs-select__input");
        for (let i = 0; i < cols.length; i++) {
          cols[i].style["color"] = "black";
          cols[i].style["background"] = "white";
          cols[i].style["min-height"] = "30%";
        }
      }, 300);
    },
    showInfoRequest(data) {
      this.formToSendApplicant = data;
      if (typeof this.formToSendApplicant["photos"] == "string") {
        this.formToSendApplicant["photos"] = this.formToSendApplicant["photos"].split(
          ","
        );
      }
      this.showInformationRequest = true;
    },
    changeValueSearch(ev) {
      this.carsCatalogCopy = filterByValue(this.carsCatalog, ev.target.value);
    },
    changeSelectBrand() {
      this.count += 1;
    },
    changeSelect(ev) {
      this.count += 1;
      if (ev === "Disabled") ev = "";
      this.carsCatalogCopy = filterByValue(this.carsCatalog, ev);
    },
    confirmDeleteBrand() {
      const loading = this.$vs.loading({
        text: "Loading...",
        type: "corners",
      });
      services
        .delete("delete_brand?_id=" + this.carToDelete, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(() => {
          this.$vs.notification({
            progress: "auto",
            color: "primary",
            position: null,
            title: "Succes !",
            text: `Brand deleted 🚗`,
          });
          this.showAllBrands();
        })
        .finally(() => loading.close());
      this.showModalDeleteBrand = false;
    },
    confirmDeleteRequest() {
      const loading = this.$vs.loading({
        text: "Loading...",
        type: "corners",
      });
      services
        .delete("delete_request?_id=" + this.requToDelete, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(() => {
          this.$vs.notification({
            progress: "auto",
            color: "primary",
            position: null,
            title: "Succes !",
            text: `Request deleted 🚗`,
          });
          this.showAllRequests();
        })
        .finally(() => loading.close());
      this.showModalDeleteRequest = false;
    },
    confirmDeleteUser() {
      const loading = this.$vs.loading({
        text: "Loading...",
        type: "corners",
      });
      services
        .delete("delete_user?_id=" + this.userToDelete, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(() => {
          this.$vs.notification({
            progress: "auto",
            color: "primary",
            position: null,
            title: "Succes !",
            text: `User deleted 👤`,
          });
          this.showAllUsers();

          this.countTableUser += 1;
        })
        .finally(() => loading.close());
      this.showModalDeleteUser = false;
    },
    modalDeleteBrand(data) {
      this.showModalDeleteBrand = true;
      this.carToDelete = data._id["$oid"];
    },
    modalDeleteRequest(data) {
      this.showModalDeleteRequest = true;
      this.requToDelete = data._id["$oid"];
    },
    modalDeleteUser(data) {
      this.showModalDeleteUser = true;
      this.userToDelete = data._id["$oid"];
    },
    modalEditBrand(data) {
      this.showModalEditBrand = true;
      setTimeout(function () {
        var cols = document.getElementsByClassName("vs-input");
        for (let i = 0; i < cols.length; i++) {
          cols[i].style.width = "99%";
        }
      }, 100);
      this.brandToEdit = data;
    },
    editRequest() {
      if (isEmpty(this.requestToEdit.requestShowed)) {
        this.$vs.notification({
          progress: "auto",
          color: "danger",
          position: null,
          title: "An error has occurred!",
          text: `All fields are required 🎫`,
        });
      } else {
        const loading = this.$vs.loading({
          text: "Loading...",
          type: "corners",
        });
        let formData = new FormData();
        formData.append("requestShowed", this.requestToEdit.requestShowed);
        formData.append("_id", this.requestToEdit._id["$oid"]);
        services
          .post("edit_request", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.showAllRequests();
            this.$vs.notification({
              progress: "auto",
              color: "primary",
              position: null,
              title: "Succes !",
              text: `Request edited 🎟`,
            });

            this.countTableRequests += 1;
          })
          .finally(() => loading.close());
      }
    },
    modalEditRequest(data) {
      this.showModalEditRequest = true;
      setTimeout(function () {
        var cols = document.getElementsByClassName("vs-input");
        for (let i = 0; i < cols.length; i++) {
          cols[i].style.width = "99%";
        }
      }, 100);
      this.requestToEdit = data;
    },
    createBrand() {
      if (isEmpty(this.brandName)) {
        this.$vs.notification({
          progress: "auto",
          color: "danger",
          position: null,
          title: "An error has occurred!",
          text: `All fields are required 🎫`,
        });
      } else {
        const loading = this.$vs.loading({
          text: "Loading...",
          type: "corners",
        });
        let formData = new FormData();
        formData.append("name", this.brandName);
        services
          .post("add_brand", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$vs.notification({
              progress: "auto",
              color: "primary",
              position: null,
              title: "Succes !",
              text: `Brand added 🎟`,
            });
            this.brandName = "";
            this.showAllBrands();
          })
          .finally(() => loading.close());
      }
    },
    editBrand() {
      if (isEmpty(this.brandToEdit.name)) {
        this.$vs.notification({
          progress: "auto",
          color: "danger",
          position: null,
          title: "An error has occurred!",
          text: `All fields are required 🎫`,
        });
      } else {
        const loading = this.$vs.loading({
          text: "Loading...",
          type: "corners",
        });
        let formData = new FormData();
        formData.append("name", this.brandToEdit.name);
        formData.append("_id", this.brandToEdit._id["$oid"]);
        services
          .post("edit_brand", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$vs.notification({
              progress: "auto",
              color: "primary",
              position: null,
              title: "Succes !",
              text: `Brand edited 🎟`,
            });
          })
          .finally(() => loading.close());
      }
    },
    showImageInRoute(url) {
      window.open(url);
    },
    infoCatalog(data) {
      this.carSelected = data;
      this.activeDialogInfoCar = !this.activeDialogInfoCar;
    },
    confirmDeleteCar() {
      services
        .delete("delete_catalog?_id=" + this.carToDelete, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(() => {
          this.$vs.notification({
            progress: "auto",
            color: "primary",
            position: null,
            title: "Succes !",
            text: `Car deleted 🚗`,
          });
          this.showAllCatalog();
        });

      this.dialogDeleteCar = false;
    },
    deleteCatalog(data) {
      this.dialogDeleteCar = true;
      this.carToDelete = data._id["$oid"];
    },
    showDialogNewCar() {
      this.formAddCar.name = "";
      this.formAddCar.description = "";
      this.formAddCar.brand = "";
      this.formAddCar.salesPrice = "";
      this.activeDialogNewCar = !this.activeDialogNewCar;
    },
    showDialogNewUser() {
      this.formAddUser.username = "";
      this.formAddUser.password = "";
      this.activeDialogNewUser = !this.activeDialogNewUser;
    },

    uploadImageSuccess(formData, index, fileList) {
      this.fileImages = fileList;
    },
    beforeRemove(index, done, fileList) {
      this.fileImages = fileList;
      var r = confirm("remove image");
      if (r == true) {
        done();
      } else {
        console.log("error");
      }
    },
    editImage(formData, index, fileList) {
      this.fileImages = fileList;
    },
    openLoadingPercentage() {
      const loading = this.$vs.loading({
        percent: this.percent,
        type: "waves",
      });
      let interval = setInterval(() => {
        if (this.percent <= 100) {
          loading.changePercent(`  ${this.percent}% \nUploading images...`);
        }
        if (this.percent == 100) {
          loading.close();
          clearInterval(interval);
          this.percent = 0;
        }
      }, 40);
    },
    addCarButton() {
      if (
        isEmpty(this.formAddCar.name) ||
        isEmpty(this.formAddCar.description) ||
        isEmpty(this.formAddCar.brand) ||
        isEmpty(this.formAddCar.salesPrice)
      ) {
        this.$vs.notification({
          progress: "auto",
          color: "danger",
          position: null,
          title: "An error has occurred!",
          text: `All fields are required 🎫`,
        });
      } else if (this.fileImages.length == 0) {
        this.$vs.notification({
          progress: "auto",
          color: "danger",
          position: null,
          title: "An error has occurred!",
          text: `Images are required 📸`,
        });
      } else {
        let formData = new FormData();
        this.fileImages.forEach((item) => {
          let jsonItem = JSON.parse(JSON.stringify(item));
          let file = dataURLtoFile(jsonItem.path, jsonItem.name);
          formData.append(jsonItem.name, file);
        });
        formData.append("name", this.formAddCar.name);
        formData.append("description", this.formAddCar.description);
        formData.append("brand", this.formAddCar.brand);
        formData.append("salesPrice", this.formAddCar.salesPrice);
        let globalThis = this;
        this.openLoadingPercentage();
        services
          .post("add_catalog", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            onUploadProgress: function (progressEvent) {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              globalThis.percent = percentCompleted;
            },
          })
          .then(() => {
            this.$vs.notification({
              progress: "auto",
              color: "primary",
              position: null,
              title: "Succes !",
              text: `Car added 🚗`,
            });
            this.formAddCar.name = "";
            this.formAddCar.description = "";
            this.formAddCar.brand = "";
            this.formAddCar.salesPrice = "";
            this.showAllCatalog();
          })
          .catch(() => {
            this.$vs.notification({
              progress: "auto",
              color: "danger",
              position: null,
              title: "An error has occurred!",
              text: `Error Occurred, Please contact system administrator 🦑`,
            });
          });
      }
    },
    showAllCatalog() {
      const loading = this.$vs.loading({
        text: "Loading...",
        type: "corners",
      });
      let globalThis = this;
      services
        .get("show_catalog")
        .then((res) => {
          globalThis.carsCatalog = res.data.data;
          globalThis.carsCatalogCopy = res.data.data;
        })
        .catch(() => {
          console.log("Error en show_catalog");
        })
        .finally(() => loading.close());
    },
    showAllRequests() {
      let globalThis = this;
      let headers = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      services
        .get("get_request", headers)
        .then((res) => {
          globalThis.requests = res.data.data;
          this.countTableRequests += 1;
        })
        .catch(() => {
          localStorage.setItem("token", null);
          localStorage.setItem("user", false);
          this.$store.dispatch("user", false);
        });
    },
    showAllBrands() {
      let globalThis = this;
      let headers = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      services
        .get("show_brand", headers)
        .then((res) => {
          globalThis.brandsCatalog = res.data.data;
        })
        .catch(() => {
          localStorage.setItem("token", null);
          localStorage.setItem("user", false);
          this.$store.dispatch("user", false);

          //this.$router.push({ name: "admin" });
          this.$router.push({ path: "/login" });
        });
    },
    showAllUsers() {
      let globalThis = this;
      let headers = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      services
        .get("show_users", headers)
        .then((res) => {
          globalThis.users = res.data.data;
          this.countTableUser += 1;
        })
        .catch(() => {
          localStorage.setItem("token", null);
          localStorage.setItem("user", false);
          this.$store.dispatch("user", false);
        });
    },
  },
  beforeMount() {
    this.showAllBrands();
    this.showAllRequests();
    this.showAllUsers();
  },
  mounted() {
    var cols = document.getElementsByClassName("vs-input");
    for (let i = 0; i < cols.length; i++) {
      cols[i].style.width = "100%";
    }
    this.showAllCatalog();
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

#my-strictly-unique-vue-upload-multiple-image {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
</style>
