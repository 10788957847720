<template>
  <div class="bodyColorHome">
    <html lang="en">

    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link href="https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css" rel="stylesheet" />
      <title>ESTEBAN MOTORS</title>
    </head>

    <body>
      <header class="header" id="header" v-if="!isMobile()">
        <nav class="nav container">
          <vs-navbar style="background-color: white; z-index: 99999 !important" not-line center-collapsed
            v-model="active">
            <template #left>
              <img src="../assets/logoEsteban.jpg" width="120px" height="100px" alt="" />
            </template>
            <a href="#home" style="color: #2c3e50">
              <vs-navbar-item :active="active == 'guide'" id="guide">
                New cars added
              </vs-navbar-item>
            </a>
            <a href="#about" style="color: #2c3e50">
              <vs-navbar-item :active="active == 'docs'" id="docs">
                About us
              </vs-navbar-item>
            </a>
            <a href="#search" style="color: #2c3e50">
              <vs-navbar-item :active="active == 'components'" id="components">
                <b>Buy your next car and <br />apply for credit</b>
              </vs-navbar-item>
            </a>
            <a href="#information" style="color: #2c3e50">
              <vs-navbar-item :active="active == 'license'" id="license">
                More information
              </vs-navbar-item>
            </a>
            <template #right>
              <!--<vs-button flat>Login</vs-button>
                <vs-button>Get Started</vs-button>-->
            </template>
          </vs-navbar>

          <!-- Toggle button -->
          <div class="nav__toggle" id="nav-toggle">
            <i class="ri-menu-line"></i>
          </div>
        </nav>
      </header>

      <header class="header" id="header" v-if="isMobile()">
        <nav class="nav container">
          <vs-navbar style="background-color: white; z-index: 99999 !important" not-line center-collapsed
            v-model="active">
            <template #left>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-menu-up"
                viewBox="0 0 16 16">
                <path
                  d="M7.646 15.854a.5.5 0 0 0 .708 0L10.207 14H14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h3.793l1.853 1.854zM1 9V6h14v3H1zm14 1v2a1 1 0 0 1-1 1h-3.793a1 1 0 0 0-.707.293l-1.5 1.5-1.5-1.5A1 1 0 0 0 5.793 13H2a1 1 0 0 1-1-1v-2h14zm0-5H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v2zM2 11.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 0-1h-8a.5.5 0 0 0-.5.5zm0-4a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11a.5.5 0 0 0-.5.5zm0-4a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 0-1h-6a.5.5 0 0 0-.5.5z" />
              </svg>&nbsp;<span @click="activeSidebarMenu = !activeSidebarMenu"><b
                  style="font-size: 17px; cursor: pointer">MENU</b></span>
            </template>
            <img src="../assets/logoEsteban.jpg" width="100px" height="10px" alt="" />
          </vs-navbar>
        </nav>
      </header>

      <!-- =========== MAIN ================= -->
      <main class="main">
        <vs-sidebar absolute style="
              z-index: 99999 !important;
              position: fixed !important;
              top: 0 !important;
            " v-model="activeMenu" :open.sync="activeSidebarMenu">
          <template #logo>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-closed"
              viewBox="0 0 16 16">
              <path
                d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2zm1 13h8V2H4v13z" />
              <path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0z" />
            </svg>
            &nbsp;<b @click="activeSidebarMenu = !activeSidebarMenu" style="font-size: 17px; cursor: pointer">CLOSE
              MENU</b>
          </template>
          <vs-sidebar-item id="home">
            <template #icon>
              <b-icon-shop></b-icon-shop>
            </template>
            <a href="#home" style="color: #2c3e50"> New cars added </a>
          </vs-sidebar-item>
          <vs-sidebar-item id="about">
            <template #icon>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-file-person" viewBox="0 0 16 16">
                <path
                  d="M12 1a1 1 0 0 1 1 1v10.755S12 11 8 11s-5 1.755-5 1.755V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
                <path d="M8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
            </template>
            <a href="#about" style="color: #2c3e50"> About us </a>
          </vs-sidebar-item>
          <vs-sidebar-item id="search">
            <template #icon>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-car-front"
                viewBox="0 0 16 16">
                <path
                  d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z" />
                <path fill-rule="evenodd"
                  d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z" />
              </svg>
            </template>
            <a href="#search" style="color: #2c3e50">
              <b>Buy your next car and <br />apply for credit</b></a>
          </vs-sidebar-item>
          <vs-sidebar-item id="information">
            <template #icon>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-lg"
                viewBox="0 0 16 16">
                <path
                  d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704l1.323-6.208Zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0Z" />
              </svg>
            </template>
            <a href="#information" style="color: #2c3e50"> <b>More information</b></a>
          </vs-sidebar-item>
        </vs-sidebar>
        <vue-whatsapp-widget :messages="['Hi there 👋 How can I help you ?']" textReply="Welcome !"
          companyLogo="https://cdn-icons-png.flaticon.com/512/3361/3361506.png" companyName="Esteban Motors"
          phoneNumber="+13378896063" />

        <!-- ============= HOME ============ -->
        <div class="shape shape__big"></div>
        <div class="shape shape__small"></div>

        <section class="home section" id="home">
          <div class="home__container container grid">
            <div class="home__data">
              <h1 class="home__title" v-if="!isMobile()">
                <a href="https://www.facebook.com/Estebanmotorsllc"><img style="width: 40px; cursor: pointer"
                    src="../assets/facebook.png" alt="" class="about__img" /></a>
                &nbsp;
                <a href="https://www.instagram.com/estebanmotors.llc/">
                  <img style="width: 40px; cursor: pointer" src="../assets/instagram.png" alt=""
                    class="about__img" /></a>
                Choose The Best Car And Contact Us
              </h1>
              <h1 class="home__title" v-if="isMobile()">
                <a href="https://www.facebook.com/Estebanmotorsllc"><img style="width: 20px; cursor: pointer"
                    src="../assets/facebook.png" alt="" class="about__img" /></a>
                &nbsp;
                <a href="https://www.instagram.com/estebanmotors.llc/">
                  <img style="width: 20px; cursor: pointer" src="../assets/instagram.png" alt=""
                    class="about__img" /></a>
                Choose The Best Car And Contact Us
              </h1>
              <h2 class="home__subtitle">{{ carInfoSelectedInSlide.brandType }}</h2>

              <h3 v-if="carInfoSelectedInSlide.carName" class="home__elec">
                <i class="ri-flashlight-fill"></i>
                {{ carInfoSelectedInSlide.carName }}
              </h3>
              <br />
              <div class="center" v-if="carInfoSelectedInSlide.carName">
                <vs-button @click="infoCatalogBestCar()" block gradient>
                  <b-icon-info-lg></b-icon-info-lg> More info
                </vs-button>
              </div>
            </div>

            <div class="over-hide px-4 px-sm-0">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-10 col-xl-8 align-self-center">
                    <div class="section mx-auto text-center slider-height-padding">
                      <span :key="car.id" v-for="car in newCarsAdded">
                        <input @click="handleChangeSlideCar(car)" :class="car.class" type="radio" :id="car.id"
                          name="slider" :checked="car.checked" />
                        <label :for="car.for" v-bind:style="{
                          'background-image': 'url(' + car.image + ')',
                        }"></label>
                        <ul>
                          <li v-bind:style="{
                            'background-image': 'url(' + car.image + ')',
                          }" :key="car.id" v-for="car in newCarsAdded">
                            <span>{{ car.brandType }}</span>
                          </li>
                        </ul>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- ======== ABOUT ==============-->
        <section class="about" id="about">
          <div class="about__container container grid">
            <div class="about__group">
              <img src="../assets/estebanAboutUs.jpg" alt="" class="about__img" />
              <div class="about__card">
                <h3 class="about__card-title">100+</h3>
                <p class="about__card-description">Cars in our company</p>
              </div>
            </div>

            <div class="about__data">
              <h2 class="section__title about__title">
                Cars in excellent conditions <br />
              </h2>

              <p class="about__description">
                We are the Esteban Motors family! We are a company founded in November
                2015 with the objective of selling used cars in excellent conditions at
                affordable prices for all our customers.
              </p>
              <vs-button gradient block :active="true" href="#information">
                Know more
              </vs-button>
            </div>
          </div>
        </section>

        <span id="search"></span>
        <h2 class="section__title" style="margin-top: 140px">
          Search and select <br />
          your favourite car and apply for credit 🚗📢!
        </h2>
        <vs-dialog prevent-close blur scroll full-screen style="max-height: 95%" v-model="activeDialogInfoCar">
          <template #header>
            <h2 class="not-margin"><b>Car information </b></h2>
          </template>
          <vs-card-group>
            <vs-card :key="card" v-for="card in carSelected.photos">
              <template #title> </template>
              <template #img>
                <img @click="showImageInRoute(url_image + 'static/' + card)" :src="url_image + 'static/' + card"
                  alt="" />
              </template>
              <template #text> </template>
              <template #interactions> </template>
            </vs-card>
          </vs-card-group>
          <br />
          <div style="margin-left: 10px; margin-right: 10px">
            <h3 id="header-title" style="font-size: 20px">{{ carSelected.name }}</h3>
            <h2 id="header-title" style="font-size: 15px; margin-bottom: 10px">
              {{ carSelected.brand }}
              <br />
              ${{ carSelected.salesPrice }}
            </h2>
            <hr />
            <span style="margin-top: 10px">{{ carSelected.description }}</span>
          </div>
          <vs-button style="width: 97%; margin-left: 1px" block @click="activeCollapse()">
            {{ activeButtonCollapse ? "Close" : "Apply For Credit !" }}
          </vs-button>
          <br />
          <section style="background: #d8d8d8; padding: 5px; border-radius: 10px" v-show="activeButtonCollapse">
            <!--<template #title>Apply For Credit </template>-->
            <vs-row>
              <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
                <b>Applicant Information</b>
                <br />
                <br />
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                <vs-input color="primary" label="First Name: *" v-model="formToSendApplicant.firstNameApplicant"
                  placeholder="">
                  <template #message-danger v-if="validNameApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                <vs-input color="primary" label="Middle Name:" v-model="formToSendApplicant.middleNameApplicant"
                  placeholder="" />
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                <vs-input color="primary" label="Last Name: *" v-model="formToSendApplicant.lastNameApplicant"
                  placeholder="">
                  <template #message-danger v-if="validLastNameApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
                <vs-input color="primary" label="Address 1: *" v-model="formToSendApplicant.address1Applicant"
                  placeholder="">
                  <template #message-danger v-if="validAddress1Applicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
                <vs-input color="primary" label="Address 2:" v-model="formToSendApplicant.address2Applicant"
                  placeholder="">
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                <vs-input color="primary" label="City: *" v-model="formToSendApplicant.cityApplicant" placeholder="">
                  <template #message-danger v-if="validCityApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                <vs-select :key="countSelect" @change="changeSelectApplicant()" placeholder="Select" label="State: *"
                  :active="false" v-model="formToSendApplicant.stateApplicant">
                  <vs-option label="AB" value="AB">AB</vs-option>
                  <vs-option label="AL" value="AL">AL</vs-option>
                  <vs-option label="AK" value="AK">AK</vs-option>
                  <vs-option label="AZ" value="AZ">AZ</vs-option>
                  <vs-option label="AR" value="AR">AR</vs-option>
                  <vs-option label="BC" value="BC">BC</vs-option>
                  <vs-option label="CA" value="CA">CA</vs-option>
                  <vs-option label="CO" value="CO">CO</vs-option>
                  <vs-option label="CT" value="CT">CT</vs-option>
                  <vs-option label="DE" value="DE">DE</vs-option>
                  <vs-option label="DC" value="DC">DC</vs-option>
                  <vs-option label="FL" value="FL">FL</vs-option>
                  <vs-option label="GA" value="GA">GA</vs-option>
                  <vs-option label="HI" value="HI">HI</vs-option>
                  <vs-option label="ID" value="ID">ID</vs-option>
                  <vs-option label="IL" value="IL">IL</vs-option>
                  <vs-option label="IN" value="IN">IN</vs-option>
                  <vs-option label="IA" value="IA">IA</vs-option>
                  <vs-option label="KS" value="KS">KS</vs-option>
                  <vs-option label="KY" value="KY">KY</vs-option>
                  <vs-option label="LA" value="LA">LA</vs-option>
                  <vs-option label="ME" value="ME">ME</vs-option>
                  <vs-option label="MD" value="MD">MD</vs-option>
                  <vs-option label="MA" value="MA">MA</vs-option>
                  <vs-option label="MB" value="MB">MB</vs-option>
                  <vs-option label="MI" value="MI">MI</vs-option>
                  <vs-option label="MN" value="MN">MN</vs-option>
                  <vs-option label="MS" value="MS">MS</vs-option>
                  <vs-option label="MO" value="MO">MO</vs-option>
                  <vs-option label="MT" value="MT">MT</vs-option>
                  <vs-option label="NB" value="NB">NB</vs-option>
                  <vs-option label="NC" value="NC">NC</vs-option>
                  <vs-option label="ND" value="ND">ND</vs-option>
                  <vs-option label="NE" value="NE">NE</vs-option>
                  <vs-option label="NH" value="NH">NH</vs-option>
                  <vs-option label="NJ" value="NJ">NJ</vs-option>
                  <vs-option label="NL" value="NL">NL</vs-option>
                  <vs-option label="NM" value="NM">NM</vs-option>
                  <vs-option label="NS" value="NS">NS</vs-option>
                  <vs-option label="NT" value="NT">NT</vs-option>
                  <vs-option label="NU" value="NU">NU</vs-option>
                  <vs-option label="NV" value="NV">NV</vs-option>
                  <vs-option label="NY" value="NY">NY</vs-option>
                  <vs-option label="OH" value="OH">OH</vs-option>
                  <vs-option label="OK" value="OK">OK</vs-option>
                  <vs-option label="ON" value="ON">ON</vs-option>
                  <vs-option label="OR" value="OR">OR</vs-option>
                  <vs-option label="PA" value="PA">PA</vs-option>
                  <vs-option label="PE" value="PE">PE</vs-option>
                  <vs-option label="PR" value="PR">PR</vs-option>
                  <vs-option label="QC" value="QC">QC</vs-option>
                  <vs-option label="RI" value="RI">RI</vs-option>
                  <vs-option label="SC" value="SC">SC</vs-option>
                  <vs-option label="SD" value="SD">SD</vs-option>
                  <vs-option label="SK" value="SK">SK</vs-option>
                  <vs-option label="TN" value="TN">TN</vs-option>
                  <vs-option label="TX" value="TX">TX</vs-option>
                  <vs-option label="UT" value="UT">UT</vs-option>
                  <vs-option label="VT" value="VT">VT</vs-option>
                  <vs-option label="VA" value="VA">VA</vs-option>
                  <vs-option label="WA" value="WA">WA</vs-option>
                  <vs-option label="WV" value="WV">WV</vs-option>
                  <vs-option label="WI" value="WI">WI</vs-option>
                  <vs-option label="WY" value="WY">WY</vs-option>
                  <vs-option label="YT" value="YT">YT</vs-option>
                  <template #message-danger v-if="validStateApplicant">
                    Required
                  </template>
                </vs-select>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                <vs-input color="primary" label="ZIP: *" v-model="formToSendApplicant.zipApplicant" placeholder="">
                  <template #message-danger v-if="validZipApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-input color="primary" label="Social security: *"
                  v-model="formToSendApplicant.socialSecurityApplicant" placeholder="">
                  <template #message-danger v-if="validSocialSecurityApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-input type="date" v-model="formToSendApplicant.dateOfBirthApplicant" label="Date of birth: *">
                  <template #message-danger v-if="validDateOfBirthApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-input type="text" v-model="formToSendApplicant.driverLicenseNumberApplicant"
                  label="Drivers license number:" />
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-select :key="countSelect" @change="changeSelectApplicant()" placeholder="Select"
                  label="Drivers license state:" :active="false"
                  v-model="formToSendApplicant.driverLicenseStateApplicant">
                  <vs-option label="AB" value="AB">AB</vs-option>
                  <vs-option label="AL" value="AL">AL</vs-option>
                  <vs-option label="AK" value="AK">AK</vs-option>
                  <vs-option label="AZ" value="AZ">AZ</vs-option>
                  <vs-option label="AR" value="AR">AR</vs-option>
                  <vs-option label="BC" value="BC">BC</vs-option>
                  <vs-option label="CA" value="CA">CA</vs-option>
                  <vs-option label="CO" value="CO">CO</vs-option>
                  <vs-option label="CT" value="CT">CT</vs-option>
                  <vs-option label="DE" value="DE">DE</vs-option>
                  <vs-option label="DC" value="DC">DC</vs-option>
                  <vs-option label="FL" value="FL">FL</vs-option>
                  <vs-option label="GA" value="GA">GA</vs-option>
                  <vs-option label="HI" value="HI">HI</vs-option>
                  <vs-option label="ID" value="ID">ID</vs-option>
                  <vs-option label="IL" value="IL">IL</vs-option>
                  <vs-option label="IN" value="IN">IN</vs-option>
                  <vs-option label="IA" value="IA">IA</vs-option>
                  <vs-option label="KS" value="KS">KS</vs-option>
                  <vs-option label="KY" value="KY">KY</vs-option>
                  <vs-option label="LA" value="LA">LA</vs-option>
                  <vs-option label="ME" value="ME">ME</vs-option>
                  <vs-option label="MD" value="MD">MD</vs-option>
                  <vs-option label="MA" value="MA">MA</vs-option>
                  <vs-option label="MB" value="MB">MB</vs-option>
                  <vs-option label="MI" value="MI">MI</vs-option>
                  <vs-option label="MN" value="MN">MN</vs-option>
                  <vs-option label="MS" value="MS">MS</vs-option>
                  <vs-option label="MO" value="MO">MO</vs-option>
                  <vs-option label="MT" value="MT">MT</vs-option>
                  <vs-option label="NB" value="NB">NB</vs-option>
                  <vs-option label="NC" value="NC">NC</vs-option>
                  <vs-option label="ND" value="ND">ND</vs-option>
                  <vs-option label="NE" value="NE">NE</vs-option>
                  <vs-option label="NH" value="NH">NH</vs-option>
                  <vs-option label="NJ" value="NJ">NJ</vs-option>
                  <vs-option label="NL" value="NL">NL</vs-option>
                  <vs-option label="NM" value="NM">NM</vs-option>
                  <vs-option label="NS" value="NS">NS</vs-option>
                  <vs-option label="NT" value="NT">NT</vs-option>
                  <vs-option label="NU" value="NU">NU</vs-option>
                  <vs-option label="NV" value="NV">NV</vs-option>
                  <vs-option label="NY" value="NY">NY</vs-option>
                  <vs-option label="OH" value="OH">OH</vs-option>
                  <vs-option label="OK" value="OK">OK</vs-option>
                  <vs-option label="ON" value="ON">ON</vs-option>
                  <vs-option label="OR" value="OR">OR</vs-option>
                  <vs-option label="PA" value="PA">PA</vs-option>
                  <vs-option label="PE" value="PE">PE</vs-option>
                  <vs-option label="PR" value="PR">PR</vs-option>
                  <vs-option label="QC" value="QC">QC</vs-option>
                  <vs-option label="RI" value="RI">RI</vs-option>
                  <vs-option label="SC" value="SC">SC</vs-option>
                  <vs-option label="SD" value="SD">SD</vs-option>
                  <vs-option label="SK" value="SK">SK</vs-option>
                  <vs-option label="TN" value="TN">TN</vs-option>
                  <vs-option label="TX" value="TX">TX</vs-option>
                  <vs-option label="UT" value="UT">UT</vs-option>
                  <vs-option label="VT" value="VT">VT</vs-option>
                  <vs-option label="VA" value="VA">VA</vs-option>
                  <vs-option label="WA" value="WA">WA</vs-option>
                  <vs-option label="WV" value="WV">WV</vs-option>
                  <vs-option label="WI" value="WI">WI</vs-option>
                  <vs-option label="WY" value="WY">WY</vs-option>
                  <vs-option label="YT" value="YT">YT</vs-option>
                  <template #message-danger v-if="validStateApplicant">
                    Required
                  </template>
                </vs-select>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-input type="date" v-model="formToSendApplicant.driverLicenseExpApplicant"
                  label="Drivers license exp:" />
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-input type="number" v-model="formToSendApplicant.mobilePhoneApplicant" label="Mobile phone: *">
                  <template #message-danger v-if="validMobilePhoneApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-input type="number" v-model="formToSendApplicant.homePhoneApplicant" label="Home Phone:" />
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-input type="text" v-model="formToSendApplicant.emailApplicant" label="Email: *">
                  <template #message-danger v-if="validEmailApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
                Time at Residence:
                <br />
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-select :key="countSelect" @change="changeSelectApplicant()" placeholder="Select" label="Years: *"
                  :active="false" v-model="formToSendApplicant.yearsTimeAtResidenceApplicant">
                  <vs-option label="0" value="0">0 Years</vs-option>
                  <vs-option label="1" value="1">1 Years</vs-option>
                  <vs-option label="2" value="2">2 Years</vs-option>
                  <vs-option label="3" value="3">3 Years</vs-option>
                  <vs-option label="4" value="4">4 Years</vs-option>
                  <vs-option label="5" value="5">5 Years</vs-option>
                  <vs-option label="6" value="6">6 Years</vs-option>
                  <vs-option label="7" value="7">7 Years</vs-option>
                  <vs-option label="8" value="8">8 Years</vs-option>
                  <vs-option label="9" value="9">9 Years</vs-option>
                  <vs-option label="10" value="10">10 Years</vs-option>
                  <vs-option label="11" value="11">11 Years</vs-option>
                  <vs-option label="12" value="12">12+ Years</vs-option>
                  <template #message-danger v-if="validYearsTimeAtResidenceApplicant">
                    Required
                  </template>
                </vs-select>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-select :key="countSelect" @change="changeSelectApplicant()" placeholder="Select" label="Months: *"
                  :active="false" v-model="formToSendApplicant.monthsTimeAtResidenceApplicant">
                  <vs-option label="0" value="0">0 Months</vs-option>
                  <vs-option label="1" value="1">1 Months</vs-option>
                  <vs-option label="2" value="2">2 Months</vs-option>
                  <vs-option label="3" value="3">3 Months</vs-option>
                  <vs-option label="4" value="4">4 Months</vs-option>
                  <vs-option label="5" value="5">5 Months</vs-option>
                  <vs-option label="6" value="6">6 Months</vs-option>
                  <vs-option label="7" value="7">7 Months</vs-option>
                  <vs-option label="8" value="8">8 Months</vs-option>
                  <vs-option label="9" value="9">9 Months</vs-option>
                  <vs-option label="10" value="10">10 Months</vs-option>
                  <vs-option label="11" value="11">11 Months</vs-option>
                  <template #message-danger v-if="validMonthsTimeAtResidenceApplicant">
                    Required
                  </template>
                </vs-select>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-select :key="countSelect" placeholder="Select" label="Residence Type: *" :active="false"
                  v-model="formToSendApplicant.typeResidenceApplicant">
                  <vs-option label="Rent" value="Rent">Rent</vs-option>
                  <vs-option label="Own" value="Own">Own</vs-option>
                  <vs-option label="Living with Family" value="Living with Family">Living with Family</vs-option>
                  <vs-option label="Military Housing" value="Military Housing">Military Housing</vs-option>
                  <vs-option label="Other" value="Other">Other</vs-option>
                  <template #message-danger v-if="validTypeResidenceApplicant">
                    Required
                  </template>
                </vs-select>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-input type="text" v-model="formToSendApplicant.rentMortgageApplicant" label="Rent/Mortgage: *">
                  <template #message-danger v-if="validRentMortgageApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col :lg="12" :sm="12" :xs="12">
                <br />
                <b style="margin-bottom: 10px">Applicant Employment Information</b>
                <br />
                <br />
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-input type="text" v-model="formToSendApplicant.employerApplicant" label="Employer: *">
                  <template #message-danger v-if="validEmployerApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-input type="text" v-model="formToSendApplicant.monthlyIncomeApplicant" label="Monthly Income: *">
                  <template #message-danger v-if="validMonthlyIncomeApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
                <vs-input type="text" v-model="formToSendApplicant.ocupationApplicant" label="Occupation: *">
                  <template #message-danger v-if="validOcupationApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-input type="text" v-model="formToSendApplicant.address1OcupationApplicant" label="Adress 1:" />
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-input type="text" v-model="formToSendApplicant.address2OcupationApplicant" label="Adress 2:" />
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                <vs-input color="primary" label="City: *" v-model="formToSendApplicant.cityOcupationApplicant"
                  placeholder="">
                  <template #message-danger v-if="validCityOcupationApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                <vs-select :key="countSelect" @change="changeSelectApplicant()" placeholder="Select" label="State: *"
                  :active="false" v-model="formToSendApplicant.stateOcupationApplicant">
                  <vs-option label="AB" value="AB">AB</vs-option>
                  <vs-option label="AL" value="AL">AL</vs-option>
                  <vs-option label="AK" value="AK">AK</vs-option>
                  <vs-option label="AZ" value="AZ">AZ</vs-option>
                  <vs-option label="AR" value="AR">AR</vs-option>
                  <vs-option label="BC" value="BC">BC</vs-option>
                  <vs-option label="CA" value="CA">CA</vs-option>
                  <vs-option label="CO" value="CO">CO</vs-option>
                  <vs-option label="CT" value="CT">CT</vs-option>
                  <vs-option label="DE" value="DE">DE</vs-option>
                  <vs-option label="DC" value="DC">DC</vs-option>
                  <vs-option label="FL" value="FL">FL</vs-option>
                  <vs-option label="GA" value="GA">GA</vs-option>
                  <vs-option label="HI" value="HI">HI</vs-option>
                  <vs-option label="ID" value="ID">ID</vs-option>
                  <vs-option label="IL" value="IL">IL</vs-option>
                  <vs-option label="IN" value="IN">IN</vs-option>
                  <vs-option label="IA" value="IA">IA</vs-option>
                  <vs-option label="KS" value="KS">KS</vs-option>
                  <vs-option label="KY" value="KY">KY</vs-option>
                  <vs-option label="LA" value="LA">LA</vs-option>
                  <vs-option label="ME" value="ME">ME</vs-option>
                  <vs-option label="MD" value="MD">MD</vs-option>
                  <vs-option label="MA" value="MA">MA</vs-option>
                  <vs-option label="MB" value="MB">MB</vs-option>
                  <vs-option label="MI" value="MI">MI</vs-option>
                  <vs-option label="MN" value="MN">MN</vs-option>
                  <vs-option label="MS" value="MS">MS</vs-option>
                  <vs-option label="MO" value="MO">MO</vs-option>
                  <vs-option label="MT" value="MT">MT</vs-option>
                  <vs-option label="NB" value="NB">NB</vs-option>
                  <vs-option label="NC" value="NC">NC</vs-option>
                  <vs-option label="ND" value="ND">ND</vs-option>
                  <vs-option label="NE" value="NE">NE</vs-option>
                  <vs-option label="NH" value="NH">NH</vs-option>
                  <vs-option label="NJ" value="NJ">NJ</vs-option>
                  <vs-option label="NL" value="NL">NL</vs-option>
                  <vs-option label="NM" value="NM">NM</vs-option>
                  <vs-option label="NS" value="NS">NS</vs-option>
                  <vs-option label="NT" value="NT">NT</vs-option>
                  <vs-option label="NU" value="NU">NU</vs-option>
                  <vs-option label="NV" value="NV">NV</vs-option>
                  <vs-option label="NY" value="NY">NY</vs-option>
                  <vs-option label="OH" value="OH">OH</vs-option>
                  <vs-option label="OK" value="OK">OK</vs-option>
                  <vs-option label="ON" value="ON">ON</vs-option>
                  <vs-option label="OR" value="OR">OR</vs-option>
                  <vs-option label="PA" value="PA">PA</vs-option>
                  <vs-option label="PE" value="PE">PE</vs-option>
                  <vs-option label="PR" value="PR">PR</vs-option>
                  <vs-option label="QC" value="QC">QC</vs-option>
                  <vs-option label="RI" value="RI">RI</vs-option>
                  <vs-option label="SC" value="SC">SC</vs-option>
                  <vs-option label="SD" value="SD">SD</vs-option>
                  <vs-option label="SK" value="SK">SK</vs-option>
                  <vs-option label="TN" value="TN">TN</vs-option>
                  <vs-option label="TX" value="TX">TX</vs-option>
                  <vs-option label="UT" value="UT">UT</vs-option>
                  <vs-option label="VT" value="VT">VT</vs-option>
                  <vs-option label="VA" value="VA">VA</vs-option>
                  <vs-option label="WA" value="WA">WA</vs-option>
                  <vs-option label="WV" value="WV">WV</vs-option>
                  <vs-option label="WI" value="WI">WI</vs-option>
                  <vs-option label="WY" value="WY">WY</vs-option>
                  <vs-option label="YT" value="YT">YT</vs-option>
                  <template #message-danger v-if="validStateOcupationApplicant">
                    Required
                  </template>
                </vs-select>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                <vs-input color="primary" label="ZIP: *" v-model="formToSendApplicant.zipOcupationApplicant"
                  placeholder="">
                  <template #message-danger v-if="validZipOcupationApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
                <vs-input color="primary" type="number" label="Work phone: *"
                  v-model="formToSendApplicant.workPhoneApplicant" placeholder="">
                  <template #message-danger v-if="validWorkPhoneApplicant">
                    Required
                  </template>
                </vs-input>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
                Time on Job:
                <br />
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-select :key="countSelect" @change="changeSelectApplicant()" placeholder="Select" label="Years: *"
                  :active="false" v-model="formToSendApplicant.yearsTimeOnJobApplicant">
                  <vs-option label="0" value="0">0 Years</vs-option>
                  <vs-option label="1" value="1">1 Years</vs-option>
                  <vs-option label="2" value="2">2 Years</vs-option>
                  <vs-option label="3" value="3">3 Years</vs-option>
                  <vs-option label="4" value="4">4 Years</vs-option>
                  <vs-option label="5" value="5">5 Years</vs-option>
                  <vs-option label="6" value="6">6 Years</vs-option>
                  <vs-option label="7" value="7">7 Years</vs-option>
                  <vs-option label="8" value="8">8 Years</vs-option>
                  <vs-option label="9" value="9">9 Years</vs-option>
                  <vs-option label="10" value="10">10 Years</vs-option>
                  <vs-option label="11" value="11">11 Years</vs-option>
                  <vs-option label="12" value="12">12+ Years</vs-option>

                  <template #message-danger v-if="validYearsTimeOnJobApplicant">
                    Required
                  </template>
                </vs-select>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                <vs-select :key="countSelect" @change="changeSelectApplicant()" placeholder="Select" label="Months: *"
                  :active="false" v-model="formToSendApplicant.monthsTimeOnJobApplicant">
                  <vs-option label="0" value="0">0 Months</vs-option>
                  <vs-option label="1" value="1">1 Months</vs-option>
                  <vs-option label="2" value="2">2 Months</vs-option>
                  <vs-option label="3" value="3">3 Months</vs-option>
                  <vs-option label="4" value="4">4 Months</vs-option>
                  <vs-option label="5" value="5">5 Months</vs-option>
                  <vs-option label="6" value="6">6 Months</vs-option>
                  <vs-option label="7" value="7">7 Months</vs-option>
                  <vs-option label="8" value="8">8 Months</vs-option>
                  <vs-option label="9" value="9">9 Months</vs-option>
                  <vs-option label="10" value="10">10 Months</vs-option>
                  <vs-option label="11" value="11">11 Months</vs-option>
                  <template #message-danger v-if="validMonthsTimeOnJobApplicant">
                    Required
                  </template>
                </vs-select>
              </vs-col>
              <vs-col :lg="12" :sm="12" :xs="12">
                <br />
                <b style="margin-bottom: 10px">Do you have a co-buyer?</b>
                <br />
                <vs-button @click="activeCollapseCoBuyer()">
                  {{ activeButtonCollapseCoBuyer ? "Remove co-buyer" : "Add co-buyer" }}
                </vs-button>
              </vs-col>
              <vs-row v-show="activeButtonCollapseCoBuyer">
                <vs-col v-show="activeButtonCollapseCoBuyer" :lg="12" :sm="12" :xs="12">
                  <br />
                  <b style="margin-bottom: 10px">Co-Buyer Information</b>
                  <br />
                  <br />
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                  <vs-input color="primary" label="First Name: *" v-show="activeButtonCollapseCoBuyer"
                    v-model="formToSendApplicant.firstNameCoBuyer" placeholder="">
                    <template #message-danger v-if="validFirstNameCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                  <vs-input color="primary" v-show="activeButtonCollapseCoBuyer" label="Middle Name:"
                    v-model="formToSendApplicant.middleNameCoBuyer" placeholder="" />
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                  <vs-input color="primary" label="Last Name: *" v-show="activeButtonCollapseCoBuyer"
                    v-model="formToSendApplicant.lastNameCoBuyer" placeholder="">
                    <template #message-danger v-if="validLastNameCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" color="primary" label="Address 1: *"
                    v-model="formToSendApplicant.address1CoBuyer" placeholder="">
                    <template #message-danger v-if="validAddress1CoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" color="primary" label="Address 2:"
                    v-model="formToSendApplicant.address2CoBuyer" placeholder="" />
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" color="primary" label="City: *"
                    v-model="formToSendApplicant.cityCoBuyer" placeholder="">
                    <template #message-danger v-if="validCityCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                  <vs-select v-show="activeButtonCollapseCoBuyer" :key="countSelect" @change="changeSelectApplicant()"
                    placeholder="Select" label="State: *" :active="false" v-model="formToSendApplicant.stateCoBuyer">
                    <vs-option label="AB" value="AB">AB</vs-option>
                    <vs-option label="AL" value="AL">AL</vs-option>
                    <vs-option label="AK" value="AK">AK</vs-option>
                    <vs-option label="AZ" value="AZ">AZ</vs-option>
                    <vs-option label="AR" value="AR">AR</vs-option>
                    <vs-option label="BC" value="BC">BC</vs-option>
                    <vs-option label="CA" value="CA">CA</vs-option>
                    <vs-option label="CO" value="CO">CO</vs-option>
                    <vs-option label="CT" value="CT">CT</vs-option>
                    <vs-option label="DE" value="DE">DE</vs-option>
                    <vs-option label="DC" value="DC">DC</vs-option>
                    <vs-option label="FL" value="FL">FL</vs-option>
                    <vs-option label="GA" value="GA">GA</vs-option>
                    <vs-option label="HI" value="HI">HI</vs-option>
                    <vs-option label="ID" value="ID">ID</vs-option>
                    <vs-option label="IL" value="IL">IL</vs-option>
                    <vs-option label="IN" value="IN">IN</vs-option>
                    <vs-option label="IA" value="IA">IA</vs-option>
                    <vs-option label="KS" value="KS">KS</vs-option>
                    <vs-option label="KY" value="KY">KY</vs-option>
                    <vs-option label="LA" value="LA">LA</vs-option>
                    <vs-option label="ME" value="ME">ME</vs-option>
                    <vs-option label="MD" value="MD">MD</vs-option>
                    <vs-option label="MA" value="MA">MA</vs-option>
                    <vs-option label="MB" value="MB">MB</vs-option>
                    <vs-option label="MI" value="MI">MI</vs-option>
                    <vs-option label="MN" value="MN">MN</vs-option>
                    <vs-option label="MS" value="MS">MS</vs-option>
                    <vs-option label="MO" value="MO">MO</vs-option>
                    <vs-option label="MT" value="MT">MT</vs-option>
                    <vs-option label="NB" value="NB">NB</vs-option>
                    <vs-option label="NC" value="NC">NC</vs-option>
                    <vs-option label="ND" value="ND">ND</vs-option>
                    <vs-option label="NE" value="NE">NE</vs-option>
                    <vs-option label="NH" value="NH">NH</vs-option>
                    <vs-option label="NJ" value="NJ">NJ</vs-option>
                    <vs-option label="NL" value="NL">NL</vs-option>
                    <vs-option label="NM" value="NM">NM</vs-option>
                    <vs-option label="NS" value="NS">NS</vs-option>
                    <vs-option label="NT" value="NT">NT</vs-option>
                    <vs-option label="NU" value="NU">NU</vs-option>
                    <vs-option label="NV" value="NV">NV</vs-option>
                    <vs-option label="NY" value="NY">NY</vs-option>
                    <vs-option label="OH" value="OH">OH</vs-option>
                    <vs-option label="OK" value="OK">OK</vs-option>
                    <vs-option label="ON" value="ON">ON</vs-option>
                    <vs-option label="OR" value="OR">OR</vs-option>
                    <vs-option label="PA" value="PA">PA</vs-option>
                    <vs-option label="PE" value="PE">PE</vs-option>
                    <vs-option label="PR" value="PR">PR</vs-option>
                    <vs-option label="QC" value="QC">QC</vs-option>
                    <vs-option label="RI" value="RI">RI</vs-option>
                    <vs-option label="SC" value="SC">SC</vs-option>
                    <vs-option label="SD" value="SD">SD</vs-option>
                    <vs-option label="SK" value="SK">SK</vs-option>
                    <vs-option label="TN" value="TN">TN</vs-option>
                    <vs-option label="TX" value="TX">TX</vs-option>
                    <vs-option label="UT" value="UT">UT</vs-option>
                    <vs-option label="VT" value="VT">VT</vs-option>
                    <vs-option label="VA" value="VA">VA</vs-option>
                    <vs-option label="WA" value="WA">WA</vs-option>
                    <vs-option label="WV" value="WV">WV</vs-option>
                    <vs-option label="WI" value="WI">WI</vs-option>
                    <vs-option label="WY" value="WY">WY</vs-option>
                    <vs-option label="YT" value="YT">YT</vs-option>

                    <template #message-danger v-if="validStateCoBuyer">
                      Required
                    </template>
                  </vs-select>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" color="primary" label="ZIP: *"
                    v-model="formToSendApplicant.zipCoBuyer" placeholder="">
                    <template #message-danger v-if="validZipCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" color="primary" label="Social security: *"
                    v-model="formToSendApplicant.socialSecurityCoBuyer" placeholder="">
                    <template #message-danger v-if="validSocialSecurityCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" type="date"
                    v-model="formToSendApplicant.dateOfBirthCoBuyer" label="Date of birth: *">
                    <template #message-danger v-if="validDateOfBirthCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" type="text"
                    v-model="formToSendApplicant.driverLicenseNumberCoBuyer" label="Drivers license number:" />
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-select :key="countSelect" @change="changeSelectApplicant()" placeholder="Select"
                    label="Drivers license state:" :active="false"
                    v-model="formToSendApplicant.driverLicenseStateCoBuyer">
                    <vs-option label="AB" value="AB">AB</vs-option>
                    <vs-option label="AL" value="AL">AL</vs-option>
                    <vs-option label="AK" value="AK">AK</vs-option>
                    <vs-option label="AZ" value="AZ">AZ</vs-option>
                    <vs-option label="AR" value="AR">AR</vs-option>
                    <vs-option label="BC" value="BC">BC</vs-option>
                    <vs-option label="CA" value="CA">CA</vs-option>
                    <vs-option label="CO" value="CO">CO</vs-option>
                    <vs-option label="CT" value="CT">CT</vs-option>
                    <vs-option label="DE" value="DE">DE</vs-option>
                    <vs-option label="DC" value="DC">DC</vs-option>
                    <vs-option label="FL" value="FL">FL</vs-option>
                    <vs-option label="GA" value="GA">GA</vs-option>
                    <vs-option label="HI" value="HI">HI</vs-option>
                    <vs-option label="ID" value="ID">ID</vs-option>
                    <vs-option label="IL" value="IL">IL</vs-option>
                    <vs-option label="IN" value="IN">IN</vs-option>
                    <vs-option label="IA" value="IA">IA</vs-option>
                    <vs-option label="KS" value="KS">KS</vs-option>
                    <vs-option label="KY" value="KY">KY</vs-option>
                    <vs-option label="LA" value="LA">LA</vs-option>
                    <vs-option label="ME" value="ME">ME</vs-option>
                    <vs-option label="MD" value="MD">MD</vs-option>
                    <vs-option label="MA" value="MA">MA</vs-option>
                    <vs-option label="MB" value="MB">MB</vs-option>
                    <vs-option label="MI" value="MI">MI</vs-option>
                    <vs-option label="MN" value="MN">MN</vs-option>
                    <vs-option label="MS" value="MS">MS</vs-option>
                    <vs-option label="MO" value="MO">MO</vs-option>
                    <vs-option label="MT" value="MT">MT</vs-option>
                    <vs-option label="NB" value="NB">NB</vs-option>
                    <vs-option label="NC" value="NC">NC</vs-option>
                    <vs-option label="ND" value="ND">ND</vs-option>
                    <vs-option label="NE" value="NE">NE</vs-option>
                    <vs-option label="NH" value="NH">NH</vs-option>
                    <vs-option label="NJ" value="NJ">NJ</vs-option>
                    <vs-option label="NL" value="NL">NL</vs-option>
                    <vs-option label="NM" value="NM">NM</vs-option>
                    <vs-option label="NS" value="NS">NS</vs-option>
                    <vs-option label="NT" value="NT">NT</vs-option>
                    <vs-option label="NU" value="NU">NU</vs-option>
                    <vs-option label="NV" value="NV">NV</vs-option>
                    <vs-option label="NY" value="NY">NY</vs-option>
                    <vs-option label="OH" value="OH">OH</vs-option>
                    <vs-option label="OK" value="OK">OK</vs-option>
                    <vs-option label="ON" value="ON">ON</vs-option>
                    <vs-option label="OR" value="OR">OR</vs-option>
                    <vs-option label="PA" value="PA">PA</vs-option>
                    <vs-option label="PE" value="PE">PE</vs-option>
                    <vs-option label="PR" value="PR">PR</vs-option>
                    <vs-option label="QC" value="QC">QC</vs-option>
                    <vs-option label="RI" value="RI">RI</vs-option>
                    <vs-option label="SC" value="SC">SC</vs-option>
                    <vs-option label="SD" value="SD">SD</vs-option>
                    <vs-option label="SK" value="SK">SK</vs-option>
                    <vs-option label="TN" value="TN">TN</vs-option>
                    <vs-option label="TX" value="TX">TX</vs-option>
                    <vs-option label="UT" value="UT">UT</vs-option>
                    <vs-option label="VT" value="VT">VT</vs-option>
                    <vs-option label="VA" value="VA">VA</vs-option>
                    <vs-option label="WA" value="WA">WA</vs-option>
                    <vs-option label="WV" value="WV">WV</vs-option>
                    <vs-option label="WI" value="WI">WI</vs-option>
                    <vs-option label="WY" value="WY">WY</vs-option>
                    <vs-option label="YT" value="YT">YT</vs-option>
                    <template #message-danger v-if="validStateApplicant">
                      Required
                    </template>
                  </vs-select>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" type="date"
                    v-model="formToSendApplicant.driverLicenseExpCoBuyer" label="Drivers license exp:" />
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" type="number"
                    v-model="formToSendApplicant.mobilePhoneCoBuyer" label="Mobile phone: *">
                    <template #message-danger v-if="validMobilePhoneCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" type="number"
                    v-model="formToSendApplicant.homePhoneCoBuyer" label="Home Phone:" />
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" type="text" v-model="formToSendApplicant.emailCoBuyer"
                    label="Email: *">
                    <template #message-danger v-if="validEmailCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
                  <span v-show="activeButtonCollapseCoBuyer">Time at Residence:</span>
                  <br />
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-select v-show="activeButtonCollapseCoBuyer" :key="countSelect" @change="changeSelectApplicant()"
                    placeholder="Select" label="Years: *" :active="false"
                    v-model="formToSendApplicant.yearsTimeAtResidenceCoBuyer">
                    <vs-option label="0" value="0">0 Years</vs-option>
                    <vs-option label="1" value="1">1 Years</vs-option>
                    <vs-option label="2" value="2">2 Years</vs-option>
                    <vs-option label="3" value="3">3 Years</vs-option>
                    <vs-option label="4" value="4">4 Years</vs-option>
                    <vs-option label="5" value="5">5 Years</vs-option>
                    <vs-option label="6" value="6">6 Years</vs-option>
                    <vs-option label="7" value="7">7 Years</vs-option>
                    <vs-option label="8" value="8">8 Years</vs-option>
                    <vs-option label="9" value="9">9 Years</vs-option>
                    <vs-option label="10" value="10">10 Years</vs-option>
                    <vs-option label="11" value="11">11 Years</vs-option>
                    <vs-option label="12" value="12">12+ Years</vs-option>
                    <template #message-danger v-if="validYearsTimeAtResidenceCoBuyer">
                      Required
                    </template>
                  </vs-select>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-select v-show="activeButtonCollapseCoBuyer" :key="countSelect" @change="changeSelectApplicant()"
                    placeholder="Select" label="Months: *" :active="false"
                    v-model="formToSendApplicant.monthsTimeAtResidenceCoBuyer">
                    <vs-option label="0" value="0">0 Months</vs-option>
                    <vs-option label="1" value="1">1 Months</vs-option>
                    <vs-option label="2" value="2">2 Months</vs-option>
                    <vs-option label="3" value="3">3 Months</vs-option>
                    <vs-option label="4" value="4">4 Months</vs-option>
                    <vs-option label="5" value="5">5 Months</vs-option>
                    <vs-option label="6" value="6">6 Months</vs-option>
                    <vs-option label="7" value="7">7 Months</vs-option>
                    <vs-option label="8" value="8">8 Months</vs-option>
                    <vs-option label="9" value="9">9 Months</vs-option>
                    <vs-option label="10" value="10">10 Months</vs-option>
                    <vs-option label="11" value="11">11 Months</vs-option>
                    <template #message-danger v-if="validMonthsTimeAtResidenceCoBuyer">
                      Required
                    </template>
                  </vs-select>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-select v-show="activeButtonCollapseCoBuyer" :key="countSelect" placeholder="Select"
                    label="Residence Type: *" :active="false" v-model="formToSendApplicant.typeResidenceCoBuyer">
                    <vs-option label="Rent" value="Rent">Rent</vs-option>
                    <vs-option label="Own" value="Own">Own</vs-option>
                    <vs-option label="Living with Family" value="Living with Family">Living with Family</vs-option>
                    <vs-option label="Military Housing" value="Military Housing">Military Housing</vs-option>
                    <vs-option label="Other" value="Other">Other</vs-option>
                    <template #message-danger v-if="validTypeResidenceCoBuyer">
                      Required
                    </template>
                  </vs-select>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" type="text"
                    v-model="formToSendApplicant.rentMortgageCoBuyer" label="Rent/Mortgage: *">
                    <template #message-danger v-if="validRentMortgageCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" :lg="12" :sm="12" :xs="12">
                  <br />
                  <b style="margin-bottom: 10px">Co-Buyer Employment Information</b>
                  <br />
                  <br />
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" type="text"
                    v-model="formToSendApplicant.employerCoBuyer" label="Employer: *">
                    <template #message-danger v-if="validEmployerCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" type="text"
                    v-model="formToSendApplicant.monthlyIncomeCoBuyer" label="Monthly Income: *">
                    <template #message-danger v-if="validMonthlyIncomeCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" type="text"
                    v-model="formToSendApplicant.ocupationCoBuyer" label="Occupation: *">
                    <template #message-danger v-if="validOcupationCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" type="text"
                    v-model="formToSendApplicant.address1OcupationCoBuyer" label="Adress 1:" />
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" type="text"
                    v-model="formToSendApplicant.address2OcupationCoBuyer" label="Adress 2:" />
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" color="primary" label="City: *"
                    v-model="formToSendApplicant.cityOcupationCoBuyer" placeholder="">
                    <template #message-danger v-if="validCityOcupationCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                  <vs-select v-show="activeButtonCollapseCoBuyer" :key="countSelect" @change="changeSelectApplicant()"
                    placeholder="Select" label="State: *" :active="false"
                    v-model="formToSendApplicant.stateOcupationCoBuyer">
                    <vs-option label="AB" value="AB">AB</vs-option>
                    <vs-option label="AL" value="AL">AL</vs-option>
                    <vs-option label="AK" value="AK">AK</vs-option>
                    <vs-option label="AZ" value="AZ">AZ</vs-option>
                    <vs-option label="AR" value="AR">AR</vs-option>
                    <vs-option label="BC" value="BC">BC</vs-option>
                    <vs-option label="CA" value="CA">CA</vs-option>
                    <vs-option label="CO" value="CO">CO</vs-option>
                    <vs-option label="CT" value="CT">CT</vs-option>
                    <vs-option label="DE" value="DE">DE</vs-option>
                    <vs-option label="DC" value="DC">DC</vs-option>
                    <vs-option label="FL" value="FL">FL</vs-option>
                    <vs-option label="GA" value="GA">GA</vs-option>
                    <vs-option label="HI" value="HI">HI</vs-option>
                    <vs-option label="ID" value="ID">ID</vs-option>
                    <vs-option label="IL" value="IL">IL</vs-option>
                    <vs-option label="IN" value="IN">IN</vs-option>
                    <vs-option label="IA" value="IA">IA</vs-option>
                    <vs-option label="KS" value="KS">KS</vs-option>
                    <vs-option label="KY" value="KY">KY</vs-option>
                    <vs-option label="LA" value="LA">LA</vs-option>
                    <vs-option label="ME" value="ME">ME</vs-option>
                    <vs-option label="MD" value="MD">MD</vs-option>
                    <vs-option label="MA" value="MA">MA</vs-option>
                    <vs-option label="MB" value="MB">MB</vs-option>
                    <vs-option label="MI" value="MI">MI</vs-option>
                    <vs-option label="MN" value="MN">MN</vs-option>
                    <vs-option label="MS" value="MS">MS</vs-option>
                    <vs-option label="MO" value="MO">MO</vs-option>
                    <vs-option label="MT" value="MT">MT</vs-option>
                    <vs-option label="NB" value="NB">NB</vs-option>
                    <vs-option label="NC" value="NC">NC</vs-option>
                    <vs-option label="ND" value="ND">ND</vs-option>
                    <vs-option label="NE" value="NE">NE</vs-option>
                    <vs-option label="NH" value="NH">NH</vs-option>
                    <vs-option label="NJ" value="NJ">NJ</vs-option>
                    <vs-option label="NL" value="NL">NL</vs-option>
                    <vs-option label="NM" value="NM">NM</vs-option>
                    <vs-option label="NS" value="NS">NS</vs-option>
                    <vs-option label="NT" value="NT">NT</vs-option>
                    <vs-option label="NU" value="NU">NU</vs-option>
                    <vs-option label="NV" value="NV">NV</vs-option>
                    <vs-option label="NY" value="NY">NY</vs-option>
                    <vs-option label="OH" value="OH">OH</vs-option>
                    <vs-option label="OK" value="OK">OK</vs-option>
                    <vs-option label="ON" value="ON">ON</vs-option>
                    <vs-option label="OR" value="OR">OR</vs-option>
                    <vs-option label="PA" value="PA">PA</vs-option>
                    <vs-option label="PE" value="PE">PE</vs-option>
                    <vs-option label="PR" value="PR">PR</vs-option>
                    <vs-option label="QC" value="QC">QC</vs-option>
                    <vs-option label="RI" value="RI">RI</vs-option>
                    <vs-option label="SC" value="SC">SC</vs-option>
                    <vs-option label="SD" value="SD">SD</vs-option>
                    <vs-option label="SK" value="SK">SK</vs-option>
                    <vs-option label="TN" value="TN">TN</vs-option>
                    <vs-option label="TX" value="TX">TX</vs-option>
                    <vs-option label="UT" value="UT">UT</vs-option>
                    <vs-option label="VT" value="VT">VT</vs-option>
                    <vs-option label="VA" value="VA">VA</vs-option>
                    <vs-option label="WA" value="WA">WA</vs-option>
                    <vs-option label="WV" value="WV">WV</vs-option>
                    <vs-option label="WI" value="WI">WI</vs-option>
                    <vs-option label="WY" value="WY">WY</vs-option>
                    <vs-option label="YT" value="YT">YT</vs-option>
                    <template #message-danger v-if="validStateOcupationCoBuyer">
                      Required
                    </template>
                  </vs-select>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="4" :sm="4" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" color="primary" label="ZIP: *"
                    v-model="formToSendApplicant.zipOcupationCoBuyer" placeholder="">
                    <template #message-danger v-if="validZipOcupationCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
                  <vs-input v-show="activeButtonCollapseCoBuyer" color="primary" type="number" label="Work phone: *"
                    v-model="formToSendApplicant.workPhoneCoBuyer" placeholder="">
                    <template #message-danger v-if="validWorkPhoneCoBuyer">
                      Required
                    </template>
                  </vs-input>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
                  <span v-show="activeButtonCollapseCoBuyer">Time on Job:</span>
                  <br />
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-select v-show="activeButtonCollapseCoBuyer" :key="countSelect" @change="changeSelectApplicant()"
                    placeholder="Select" label="Years: *" :active="false"
                    v-model="formToSendApplicant.yearsTimeOnJobCoBuyer">
                    <vs-option label="0" value="0">0 Years</vs-option>
                    <vs-option label="1" value="1">1 Years</vs-option>
                    <vs-option label="2" value="2">2 Years</vs-option>
                    <vs-option label="3" value="3">3 Years</vs-option>
                    <vs-option label="4" value="4">4 Years</vs-option>
                    <vs-option label="5" value="5">5 Years</vs-option>
                    <vs-option label="6" value="6">6 Years</vs-option>
                    <vs-option label="7" value="7">7 Years</vs-option>
                    <vs-option label="8" value="8">8 Years</vs-option>
                    <vs-option label="9" value="9">9 Years</vs-option>
                    <vs-option label="10" value="10">10 Years</vs-option>
                    <vs-option label="11" value="11">11 Years</vs-option>
                    <vs-option label="12" value="12">12+ Years</vs-option>
                    <template #message-danger v-if="validYearsTimeOnJobCoBuyer">
                      Required
                    </template>
                  </vs-select>
                </vs-col>
                <vs-col v-show="activeButtonCollapseCoBuyer" style="margin-top: 20px" :lg="6" :sm="6" :xs="12">
                  <vs-select v-show="activeButtonCollapseCoBuyer" :key="countSelect" @change="changeSelectApplicant()"
                    placeholder="Select" label="Months: *" :active="false"
                    v-model="formToSendApplicant.monthsTimeOnJobCoBuyer">
                    <vs-option label="0" value="0">0 Months</vs-option>
                    <vs-option label="1" value="1">1 Months</vs-option>
                    <vs-option label="2" value="2">2 Months</vs-option>
                    <vs-option label="3" value="3">3 Months</vs-option>
                    <vs-option label="4" value="4">4 Months</vs-option>
                    <vs-option label="5" value="5">5 Months</vs-option>
                    <vs-option label="6" value="6">6 Months</vs-option>
                    <vs-option label="7" value="7">7 Months</vs-option>
                    <vs-option label="8" value="8">8 Months</vs-option>
                    <vs-option label="9" value="9">9 Months</vs-option>
                    <vs-option label="10" value="10">10 Months</vs-option>
                    <vs-option label="11" value="11">11 Months</vs-option>
                    <template #message-danger v-if="validMonthsTimeOnJobCoBuyer">
                      Required
                    </template>
                  </vs-select>
                </vs-col>
              </vs-row>
              <br />
              <br />
              <vs-col :lg="12" :sm="12" :xs="12">
                <br />
                <b style="margin-bottom: 10px">Additional Comments</b>
              </vs-col>
              <vs-col style="margin-top: 20px" :lg="12" :sm="12" :xs="12">
                <vs-input color="primary" type="text" v-model="formToSendApplicant.comments" placeholder="" />
              </vs-col>
              <vs-col style="margin-top: 15px" :lg="12" :sm="12" :xs="12">
                <label><input v-model="formToSendApplicant.acknowledgmentAndConsent" type="checkbox"
                    class="rounded-checkbox" id="cbox1" />
                  ACKNOWLEDGMENT AND CONSENT: <br />I certify that the above information is complete and accurate to the
                  best of my knowledge. Creditors receiving this application will retain the application whether or not
                  it is approved. Creditors may rely on this application in deciding whether to grant the requested
                  credit. False statements may subject me to criminal penalties. I authorize the creditors to obtain
                  credit reports about me on an ongoing basis during this credit transaction and to check my credit and
                  employment history on an ongoing basis during the term of the credit transaction. If this application
                  is approved, I authorize the creditor to give credit information about me to its
                  affiliates.</label><br />
              </vs-col>
              <br />
              <br />
              <vs-col style="margin-top: 15px" :lg="12" :sm="12" :xs="12">
                <label><input v-model="formToSendApplicant.acknowledgmentAndConsent2" type="checkbox"
                    class="rounded-checkbox" id="cbox1" />
                  ACKNOWLEDGMENT AND CONSENT:<br />
                  I hereby consent to receive text messages or phone calls from or on
                  behalf of the dealer or their employees to the mobile phone number I
                  provided above. By opting in, I understand that message and data rates
                  may apply. This acknowledgement constitutes my written consent to
                  receive text messages to my cell phone and phone calls, including
                  communications sent using an auto-dialer or pre-recorded message. You
                  may withdraw your consent at any time by texting "STOP".</label><br />
              </vs-col>
            </vs-row>
            <br />
            <vs-button :disabled="
              !formToSendApplicant.acknowledgmentAndConsent ||
              !formToSendApplicant.acknowledgmentAndConsent2
            " block @click="submitCreditApp()">
              Submit credit app
            </vs-button>
            <br />
          </section>
        </vs-dialog>
        <section class="popular section">
          <section>
            <br v-if="isMobile()" />
            <br v-if="isMobile()" />
            <div class="center examplex">
              <vs-navbar not-line primary text-white square v-model="active" style="
                    z-index: 1 !important;
                    overflow-x: auto !important;
                    scrollbar-width: thin;
                    background-color: hsl(219, 4%, 4%);
                  ">
                <!--<template #left>
                                  <img width="50px" src="../assets/cars.png" alt="">
                              </template>-->
                <vs-navbar-item class="vs-navbar__item" :active="true">
                  <vs-input style="border-radius: 20px" placeholder="Car" v-model="valueSearchCar"
                    @change="changeValueSearch" type="search">
                    <template #icon>
                      <b-icon-search style="color: black"></b-icon-search>
                    </template>
                  </vs-input>
                </vs-navbar-item>
                <vs-navbar-item :active="true">
                  <h1>
                    <b-icon-filter font-scale="2"></b-icon-filter>
                  </h1>
                </vs-navbar-item>
              </vs-navbar>
              <div class="square">
                <div class="ex3">
                  <vs-row v-if="carsCatalogCopy.length > 0">
                    <vs-col :key="data._id['$oid']" v-for="data in carsCatalogCopy" vs-type="flex" vs-justify="center"
                      vs-align="center" lg="2" sm="3" xs="6">
                      <!---->
                      <vs-card type="2" style="padding: 5px">
                        <template #title>
                          <h3>{{ data.name }}</h3>
                        </template>
                        <template #img>
                          <img style="width: 100%" :src="url_image + 'static/' + data.photos[0]" alt="" />
                        </template>
                        <template #text>
                          <p>{{ data.brand }}</p>
                        </template>
                        <template #interactions>
                          <vs-button @click="infoCatalog(data)" class="btn-chat" color="primary">
                            More info
                          </vs-button>
                        </template>
                      </vs-card>
                    </vs-col>
                  </vs-row>
                  <div style="margin-left: 10px" v-else>No results found</div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <!-- =========== OFFER ================ -->
        <section class="offer section" id="information">
          <div class="offer__container container grid">
            <img src="https://raw.githubusercontent.com/bedimcode/responsive-car-website/main/assets/img/offer-bg.png"
              alt="" class="offer__bg" />

            <div class="offer__data">
              <h2 class="section__title offer__title">
                Do You Want To Receive <br />
                Special Offers?
              </h2>

              <p class="offer__description">
                Be the first to receive all the information about our products and new
                cars by email by subscribing to our mailing list. Coming soon
              </p>
              <br />
              <!--<vs-button gradient :active="true"> Suscribe now </vs-button>-->
            </div>

            <img src="../assets/dodgeChanllengerBack.png" alt="" class="offer__img" />
          </div>
        </section>

        <!-- ==================== LOGOS ============= -->
        <section>
          <div class="swiper">
            <div class="slider">
              <div class="slide-track">
                <div class="slide">
                  <img src="../assets/toyota.png" />
                </div>
                <div class="slide">
                  <img src="../assets/ford.png" />
                </div>
                <div class="slide" style="width: 210px !important">
                  <img src="../assets/hyundai.png" />
                </div>
                <div class="slide" style="width: 150px !important">
                  <img src="../assets/honda.png" />
                </div>
                <div class="slide">
                  <img src="../assets/subaru.png" />
                </div>
                <div class="slide">
                  <img src="../assets/jeep.png" />
                </div>
                <div class="slide" style="width: 120px !important">
                  <img src="../assets/dodge.png" />
                </div>
                <div class="slide">
                  <img src="../assets/kia.png" />
                </div>
                <div class="slide">
                  <img src="../assets/mazda.png" />
                </div>
                <div class="slide" style="width: 200px !important">
                  <img src="../assets/nissan.png" />
                </div>
                <div class="slide">
                  <img src="../assets/chevrolet.png" />
                </div>
                <div class="slide">
                  <img src="../assets/wolskvagen.png" />
                </div>
                <div class="slide" style="width: 150px !important">
                  <img src="../assets/seat.png" />
                </div>
                <div class="slide">
                  <img src="../assets/landrover.png" />
                </div>
                <div class="slide" style="width: 120px !important">
                  <img src="../assets/ram.png" />
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </section>

        <!-- ============== FOOTER =============== -->
        <footer class="footer section">
          <div class="shape shape__big"></div>
          <div class="shape shape__small"></div>

          <div class="footer__container container grid">
            <div class="footer__content">
              <a href="" class="footer__logo">
                <b-icon-shop></b-icon-shop> Esteban motors
              </a>
              <p class="footer__description">
                <b-icon-facebook></b-icon-facebook>
                <a style="margin-left: 10px; color: white" href="https://www.facebook.com/Estebanmotorsllc">Esteban
                  Motors LLC</a>
                <br />
                <b-icon-receipt-cutoff></b-icon-receipt-cutoff>
                <span style="margin-left: 10px; color: white"><b>Sales: </b> +1 (337) 889 6063</span>
                <br />
                <b-icon-building></b-icon-building>
                <span style="margin-left: 10px; color: white"><b>Owner: </b> +1 (337) 344-0852</span>
              </p>
            </div>

            <div class="footer__content">
              <h3 class="footer__title">Map information</h3>
              <div style="margin-right: 10px">
                <l-map :zoom="zoom" :center="center" style="height: 300px; width: 400px; z-index: 97">
                  <l-tile-layer :url="url" :attribution="attribution" />
                  <l-marker :lat-lng="marker">
                    <l-popup v-show="true">
                      <div>
                        <img style="width: 500px" src="../assets/EstebanMotorsSite.png" />
                        <br />

                        4300 Cameron St, Lafayette, LA, United States, Louisiana
                      </div>
                    </l-popup>
                  </l-marker>
                </l-map>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </body>

    </html>
  </div>
</template>
<script>
import { latLng } from "leaflet";
import services from "../api/services";
import { url_imagen } from "../api/url";
import VueWhatsappWidget from "vue-whatsapp-widget";

function filterByValue(array, value) {
  return array.filter(
    (data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
}

export default {
  data() {
    return {
      activeSidebarMenu: false,
      activeMenu: false,
      formToSendApplicant: {
        acknowledgmentAndConsent: false,
        acknowledgmentAndConsent2: false,
        firstNameApplicant: "",
        middleNameApplicant: "",
        lastNameApplicant: "",
        address1Applicant: "",
        address2Applicant: "",
        cityApplicant: "",
        stateApplicant: "",
        zipApplicant: "",
        socialSecurityApplicant: "",
        dateOfBirthApplicant: "",
        driverLicenseNumberApplicant: "",
        driverLicenseStateApplicant: "",
        driverLicenseExpApplicant: "",
        mobilePhoneApplicant: "",
        homePhoneApplicant: "",
        emailApplicant: "",
        yearsTimeAtResidenceApplicant: "",
        monthsTimeAtResidenceApplicant: "",
        typeResidenceApplicant: "",
        rentMortgageApplicant: "",
        employerApplicant: "",
        monthlyIncomeApplicant: "",
        ocupationApplicant: "",
        address1OcupationApplicant: "",
        address2OcupationApplicant: "",
        cityOcupationApplicant: "",
        stateOcupationApplicant: "",
        zipOcupationApplicant: "",
        workPhoneApplicant: "",
        yearsTimeOnJobApplicant: "",
        monthsTimeOnJobApplicant: "",
        firstNameCoBuyer: "",
        middleNameCoBuyer: "",
        lastNameCoBuyer: "",
        address1CoBuyer: "",
        address2CoBuyer: "",
        cityCoBuyer: "",
        stateCoBuyer: "",
        zipCoBuyer: "",
        socialSecurityCoBuyer: "",
        dateOfBirthCoBuyer: "",
        driverLicenseNumberCoBuyer: "",
        driverLicenseStateCoBuyer: "",
        driverLicenseExpCoBuyer: "",
        mobilePhoneCoBuyer: "",
        homePhoneCoBuyer: "",
        emailCoBuyer: "",
        yearsTimeAtResidenceCoBuyer: "",
        monthsTimeAtResidenceCoBuyer: "",
        typeResidenceCoBuyer: "",
        rentMortgageCoBuyer: "",
        employerCoBuyer: "",
        monthlyIncomeCoBuyer: "",
        ocupationCoBuyer: "",
        address1OcupationCoBuyer: "",
        address2OcupationCoBuyer: "",
        cityOcupationCoBuyer: "",
        stateOcupationCoBuyer: "",
        zipOcupationCoBuyer: "",
        workPhoneCoBuyer: "",
        yearsTimeOnJobCoBuyer: "",
        monthsTimeOnJobCoBuyer: "",
        comments: "",
      },
      activeButtonCollapseCoBuyer: true,
      countButtonCollapse: 0,
      countSelect: 0,
      activeButtonCollapse: false,
      activeDialogInfoCar: false,
      url_image: url_imagen,
      valueSearchCar: "",
      carsCatalog: [],
      carsCatalogCopy: [],
      value: "",
      zoom: 7,
      center: [30.233080495419678, -92.07173566660116],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: latLng(30.233080495419678, -92.07173566660116),
      newCarsAdded: [],
      active: true,
      carSelected: {},
      carInfoSelectedInSlide: {
        carName: null,
        brandType: null,
      },
    };
  },
  name: "App",
  components: { VueWhatsappWidget },
  computed: {
    validNameApplicant() {
      return this.isEmpty(this.formToSendApplicant.firstNameApplicant);
    },
    validLastNameApplicant() {
      return this.isEmpty(this.formToSendApplicant.lastNameApplicant);
    },
    validAddress1Applicant() {
      return this.isEmpty(this.formToSendApplicant.address1Applicant);
    },
    validCityApplicant() {
      return this.isEmpty(this.formToSendApplicant.cityApplicant);
    },
    validStateApplicant() {
      return this.isEmpty(this.formToSendApplicant.stateApplicant);
    },
    validZipApplicant() {
      return this.isEmpty(this.formToSendApplicant.zipApplicant);
    },
    validSocialSecurityApplicant() {
      return this.isEmpty(this.formToSendApplicant.socialSecurityApplicant);
    },
    validDateOfBirthApplicant() {
      return this.isEmpty(this.formToSendApplicant.dateOfBirthApplicant);
    },
    validMobilePhoneApplicant() {
      return this.isEmpty(this.formToSendApplicant.mobilePhoneApplicant);
    },
    validEmailApplicant() {
      return this.isEmpty(this.formToSendApplicant.emailApplicant);
    },
    validYearsTimeAtResidenceApplicant() {
      return this.isEmpty(this.formToSendApplicant.yearsTimeAtResidenceApplicant);
    },
    validMonthsTimeAtResidenceApplicant() {
      return this.isEmpty(this.formToSendApplicant.monthsTimeAtResidenceApplicant);
    },
    validTypeResidenceApplicant() {
      return this.isEmpty(this.formToSendApplicant.typeResidenceApplicant);
    },
    validRentMortgageApplicant() {
      return this.isEmpty(this.formToSendApplicant.rentMortgageApplicant);
    },
    validEmployerApplicant() {
      return this.isEmpty(this.formToSendApplicant.employerApplicant);
    },
    validMonthlyIncomeApplicant() {
      return this.isEmpty(this.formToSendApplicant.monthlyIncomeApplicant);
    },
    validOcupationApplicant() {
      return this.isEmpty(this.formToSendApplicant.ocupationApplicant);
    },
    validCityOcupationApplicant() {
      return this.isEmpty(this.formToSendApplicant.cityOcupationApplicant);
    },
    validStateOcupationApplicant() {
      return this.isEmpty(this.formToSendApplicant.stateOcupationApplicant);
    },
    validZipOcupationApplicant() {
      return this.isEmpty(this.formToSendApplicant.zipOcupationApplicant);
    },
    validWorkPhoneApplicant() {
      return this.isEmpty(this.formToSendApplicant.workPhoneApplicant);
    },
    validYearsTimeOnJobApplicant() {
      return this.isEmpty(this.formToSendApplicant.yearsTimeOnJobApplicant);
    },
    validMonthsTimeOnJobApplicant() {
      return this.isEmpty(this.formToSendApplicant.monthsTimeOnJobApplicant);
    },
    validFirstNameCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.firstNameCoBuyer);
    },
    validLastNameCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.lastNameCoBuyer);
    },
    validAddress1CoBuyer() {
      return this.isEmpty(this.formToSendApplicant.address1CoBuyer);
    },
    validCityCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.cityCoBuyer);
    },
    validStateCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.stateCoBuyer);
    },
    validZipCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.zipCoBuyer);
    },
    validSocialSecurityCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.socialSecurityCoBuyer);
    },
    validDateOfBirthCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.dateOfBirthCoBuyer);
    },
    validMobilePhoneCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.mobilePhoneCoBuyer);
    },
    validEmailCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.emailCoBuyer);
    },
    validYearsTimeAtResidenceCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.yearsTimeAtResidenceCoBuyer);
    },
    validMonthsTimeAtResidenceCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.monthsTimeAtResidenceCoBuyer);
    },
    validTypeResidenceCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.typeResidenceCoBuyer);
    },
    validRentMortgageCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.rentMortgageCoBuyer);
    },
    validEmployerCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.employerCoBuyer);
    },
    validMonthlyIncomeCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.monthlyIncomeCoBuyer);
    },
    validOcupationCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.ocupationCoBuyer);
    },
    validCityOcupationCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.cityOcupationCoBuyer);
    },
    validStateOcupationCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.stateOcupationCoBuyer);
    },
    validZipOcupationCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.zipOcupationCoBuyer);
    },
    validWorkPhoneCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.workPhoneCoBuyer);
    },
    validYearsTimeOnJobCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.yearsTimeOnJobCoBuyer);
    },
    validMonthsTimeOnJobCoBuyer() {
      return this.isEmpty(this.formToSendApplicant.monthsTimeOnJobCoBuyer);
    },
  },
  methods: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    isEmpty(str) {
      return !str || str.length === 0;
    },
    submitCreditApp() {
      if (
        this.isEmpty(this.formToSendApplicant.firstNameApplicant) ||
        this.isEmpty(this.formToSendApplicant.lastNameApplicant) ||
        this.isEmpty(this.formToSendApplicant.address1Applicant) ||
        this.isEmpty(this.formToSendApplicant.cityApplicant) ||
        this.isEmpty(this.formToSendApplicant.stateApplicant) ||
        this.isEmpty(this.formToSendApplicant.zipApplicant) ||
        this.isEmpty(this.formToSendApplicant.socialSecurityApplicant) ||
        this.isEmpty(this.formToSendApplicant.dateOfBirthApplicant) ||
        this.isEmpty(this.formToSendApplicant.mobilePhoneApplicant) ||
        this.isEmpty(this.formToSendApplicant.emailApplicant) ||
        this.isEmpty(this.formToSendApplicant.yearsTimeAtResidenceApplicant) ||
        this.isEmpty(this.formToSendApplicant.monthsTimeAtResidenceApplicant) ||
        this.isEmpty(this.formToSendApplicant.typeResidenceApplicant) ||
        this.isEmpty(this.formToSendApplicant.rentMortgageApplicant) ||
        this.isEmpty(this.formToSendApplicant.employerApplicant) ||
        this.isEmpty(this.formToSendApplicant.monthlyIncomeApplicant) ||
        this.isEmpty(this.formToSendApplicant.ocupationApplicant) ||
        this.isEmpty(this.formToSendApplicant.cityOcupationApplicant) ||
        this.isEmpty(this.formToSendApplicant.stateOcupationApplicant) ||
        this.isEmpty(this.formToSendApplicant.zipOcupationApplicant) ||
        this.isEmpty(this.formToSendApplicant.workPhoneApplicant) ||
        this.isEmpty(this.formToSendApplicant.yearsTimeOnJobApplicant) ||
        this.isEmpty(this.formToSendApplicant.monthsTimeOnJobApplicant)
      ) {
        this.$vs.notification({
          progress: "auto",
          color: "danger",
          position: null,
          title: "An error has occurred!",
          text: `All fields are required 🎫`,
        });
      } else {
        if (
          this.activeButtonCollapseCoBuyer &&
          (this.isEmpty(this.formToSendApplicant.firstNameCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.lastNameCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.address1CoBuyer) ||
            this.isEmpty(this.formToSendApplicant.cityCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.stateCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.zipCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.socialSecurityCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.dateOfBirthCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.mobilePhoneCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.emailCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.yearsTimeAtResidenceCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.monthsTimeAtResidenceCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.typeResidenceCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.rentMortgageCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.employerCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.monthlyIncomeCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.ocupationCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.cityOcupationCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.stateOcupationCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.zipOcupationCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.workPhoneCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.yearsTimeOnJobCoBuyer) ||
            this.isEmpty(this.formToSendApplicant.monthsTimeOnJobCoBuyer))
        ) {
          this.$vs.notification({
            progress: "auto",
            color: "danger",
            position: null,
            title: "An error has occurred!",
            text: `All fields are required 🎫`,
          });
        } else {
          const loading = this.$vs.loading({
            text: "Loading...",
            type: "corners",
          });
          let formData = new FormData();
          formData.append(
            "acknowledgmentAndConsent",
            this.formToSendApplicant.acknowledgmentAndConsent
          );
          formData.append(
            "acknowledgmentAndConsent2",
            this.formToSendApplicant.acknowledgmentAndConsent2
          );
          formData.append(
            "firstNameApplicant",
            this.formToSendApplicant.firstNameApplicant
          );
          formData.append(
            "middleNameApplicant",
            this.formToSendApplicant.middleNameApplicant
          );
          formData.append(
            "lastNameApplicant",
            this.formToSendApplicant.lastNameApplicant
          );
          formData.append(
            "address1Applicant",
            this.formToSendApplicant.address1Applicant
          );
          formData.append(
            "address2Applicant",
            this.formToSendApplicant.address2Applicant
          );
          formData.append("cityApplicant", this.formToSendApplicant.cityApplicant);
          formData.append("stateApplicant", this.formToSendApplicant.stateApplicant);
          formData.append("zipApplicant", this.formToSendApplicant.zipApplicant);
          formData.append(
            "socialSecurityApplicant",
            this.formToSendApplicant.socialSecurityApplicant
          );
          formData.append(
            "dateOfBirthApplicant",
            this.formToSendApplicant.dateOfBirthApplicant
          );
          formData.append(
            "driverLicenseNumberApplicant",
            this.formToSendApplicant.driverLicenseNumberApplicant
          );
          formData.append(
            "driverLicenseStateApplicant",
            this.formToSendApplicant.driverLicenseStateApplicant
          );
          formData.append(
            "driverLicenseExpApplicant",
            this.formToSendApplicant.driverLicenseExpApplicant
          );
          formData.append(
            "mobilePhoneApplicant",
            this.formToSendApplicant.mobilePhoneApplicant
          );
          formData.append(
            "homePhoneApplicant",
            this.formToSendApplicant.homePhoneApplicant
          );
          formData.append("emailApplicant", this.formToSendApplicant.emailApplicant);
          formData.append(
            "yearsTimeAtResidenceApplicant",
            this.formToSendApplicant.yearsTimeAtResidenceApplicant
          );
          formData.append(
            "monthsTimeAtResidenceApplicant",
            this.formToSendApplicant.monthsTimeAtResidenceApplicant
          );
          formData.append(
            "typeResidenceApplicant",
            this.formToSendApplicant.typeResidenceApplicant
          );
          formData.append(
            "rentMortgageApplicant",
            this.formToSendApplicant.rentMortgageApplicant
          );
          formData.append(
            "employerApplicant",
            this.formToSendApplicant.employerApplicant
          );
          formData.append(
            "monthlyIncomeApplicant",
            this.formToSendApplicant.monthlyIncomeApplicant
          );
          formData.append(
            "ocupationApplicant",
            this.formToSendApplicant.ocupationApplicant
          );
          formData.append(
            "address1OcupationApplicant",
            this.formToSendApplicant.address1OcupationApplicant
          );
          formData.append(
            "address2OcupationApplicant",
            this.formToSendApplicant.address2OcupationApplicant
          );
          formData.append(
            "cityOcupationApplicant",
            this.formToSendApplicant.cityOcupationApplicant
          );
          formData.append(
            "stateOcupationApplicant",
            this.formToSendApplicant.stateOcupationApplicant
          );
          formData.append(
            "zipOcupationApplicant",
            this.formToSendApplicant.zipOcupationApplicant
          );
          formData.append(
            "workPhoneApplicant",
            this.formToSendApplicant.workPhoneApplicant
          );
          formData.append(
            "yearsTimeOnJobApplicant",
            this.formToSendApplicant.yearsTimeOnJobApplicant
          );
          formData.append(
            "monthsTimeOnJobApplicant",
            this.formToSendApplicant.monthsTimeOnJobApplicant
          );
          formData.append("firstNameCoBuyer", this.formToSendApplicant.firstNameCoBuyer);
          formData.append(
            "middleNameCoBuyer",
            this.formToSendApplicant.middleNameCoBuyer
          );
          formData.append("lastNameCoBuyer", this.formToSendApplicant.lastNameCoBuyer);
          formData.append("address1CoBuyer", this.formToSendApplicant.address1CoBuyer);
          formData.append("address2CoBuyer", this.formToSendApplicant.address2CoBuyer);
          formData.append("cityCoBuyer", this.formToSendApplicant.cityCoBuyer);
          formData.append("stateCoBuyer", this.formToSendApplicant.stateCoBuyer);
          formData.append("zipCoBuyer", this.formToSendApplicant.zipCoBuyer);
          formData.append(
            "socialSecurityCoBuyer",
            this.formToSendApplicant.socialSecurityCoBuyer
          );
          formData.append(
            "dateOfBirthCoBuyer",
            this.formToSendApplicant.dateOfBirthCoBuyer
          );
          formData.append(
            "driverLicenseNumberCoBuyer",
            this.formToSendApplicant.driverLicenseNumberCoBuyer
          );
          formData.append(
            "driverLicenseStateCoBuyer",
            this.formToSendApplicant.driverLicenseStateCoBuyer
          );
          formData.append(
            "driverLicenseExpCoBuyer",
            this.formToSendApplicant.driverLicenseExpCoBuyer
          );
          formData.append(
            "mobilePhoneCoBuyer",
            this.formToSendApplicant.mobilePhoneCoBuyer
          );
          formData.append("homePhoneCoBuyer", this.formToSendApplicant.homePhoneCoBuyer);
          formData.append("emailCoBuyer", this.formToSendApplicant.emailCoBuyer);
          formData.append(
            "yearsTimeAtResidenceCoBuyer",
            this.formToSendApplicant.yearsTimeAtResidenceCoBuyer
          );
          formData.append(
            "monthsTimeAtResidenceCoBuyer",
            this.formToSendApplicant.monthsTimeAtResidenceCoBuyer
          );
          formData.append(
            "typeResidenceCoBuyer",
            this.formToSendApplicant.typeResidenceCoBuyer
          );
          formData.append(
            "rentMortgageCoBuyer",
            this.formToSendApplicant.rentMortgageCoBuyer
          );
          formData.append("employerCoBuyer", this.formToSendApplicant.employerCoBuyer);
          formData.append(
            "monthlyIncomeCoBuyer",
            this.formToSendApplicant.monthlyIncomeCoBuyer
          );
          formData.append("ocupationCoBuyer", this.formToSendApplicant.ocupationCoBuyer);
          formData.append(
            "address1OcupationCoBuyer",
            this.formToSendApplicant.address1OcupationCoBuyer
          );
          formData.append(
            "address2OcupationCoBuyer",
            this.formToSendApplicant.address2OcupationCoBuyer
          );
          formData.append(
            "cityOcupationCoBuyer",
            this.formToSendApplicant.cityOcupationCoBuyer
          );
          formData.append(
            "stateOcupationCoBuyer",
            this.formToSendApplicant.stateOcupationCoBuyer
          );
          formData.append(
            "zipOcupationCoBuyer",
            this.formToSendApplicant.zipOcupationCoBuyer
          );
          formData.append("workPhoneCoBuyer", this.formToSendApplicant.workPhoneCoBuyer);
          formData.append(
            "yearsTimeOnJobCoBuyer",
            this.formToSendApplicant.yearsTimeOnJobCoBuyer
          );
          formData.append(
            "monthsTimeOnJobCoBuyer",
            this.formToSendApplicant.monthsTimeOnJobCoBuyer
          );

          formData.append("brand", this.carSelected.brand);
          formData.append("description", this.carSelected.description);
          formData.append("name", this.carSelected.name);
          formData.append("photos", this.carSelected.photos);
          formData.append("salesPrice", this.carSelected.salesPrice);

          formData.append("comments", this.formToSendApplicant.comments);

          const dt = new Date();
          const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
          formData.append(
            "actualDate",
            `${padL(dt.getMonth() + 1)}/${padL(dt.getDate())}/${dt.getFullYear()} ${padL(
              dt.getHours()
            )}:${padL(dt.getMinutes())}:${padL(dt.getSeconds())}`
          );
          formData.append("requestShowed", false);
          services
            .post("add_request", formData)
            .then(() => {
              this.$vs.notification({
                progress: "auto",
                color: "primary",
                position: null,
                title: "Succes !",
                text: `Request submit 🎟`,
              });
              this.resetForm();
            })
            .finally(() => loading.close());
        }
      }
    },
    changeSelectApplicant() {
      this.countSelect += 1;
      setTimeout(function () {
        var cols = document.getElementsByClassName("vs-select__input");
        for (let i = 0; i < cols.length; i++) {
          cols[i].style["color"] = "black";
          cols[i].style["background"] = "white";
          cols[i].style["min-height"] = "30%";
        }
      }, 300);
    },
    resetForm() {
      this.formToSendApplicant.acknowledgmentAndConsent = false;
      this.formToSendApplicant.acknowledgmentAndConsent2 = false;
      this.formToSendApplicant.firstNameApplicant = "";
      this.formToSendApplicant.middleNameApplicant = "";
      this.formToSendApplicant.lastNameApplicant = "";
      this.formToSendApplicant.address1Applicant = "";
      this.formToSendApplicant.address2Applicant = "";
      this.formToSendApplicant.cityApplicant = "";
      this.formToSendApplicant.stateApplicant = "";
      this.formToSendApplicant.zipApplicant = "";
      this.formToSendApplicant.socialSecurityApplicant = "";
      this.formToSendApplicant.dateOfBirthApplicant = "";
      this.formToSendApplicant.driverLicenseNumberApplicant = "";
      this.formToSendApplicant.driverLicenseStateApplicant = "";
      this.formToSendApplicant.driverLicenseExpApplicant = "";
      this.formToSendApplicant.mobilePhoneApplicant = "";
      this.formToSendApplicant.homePhoneApplicant = "";
      this.formToSendApplicant.emailApplicant = "";
      this.formToSendApplicant.yearsTimeAtResidenceApplicant = "";
      this.formToSendApplicant.monthsTimeAtResidenceApplicant = "";
      this.formToSendApplicant.typeResidenceApplicant = "";
      this.formToSendApplicant.rentMortgageApplicant = "";
      this.formToSendApplicant.employerApplicant = "";
      this.formToSendApplicant.monthlyIncomeApplicant = "";
      this.formToSendApplicant.ocupationApplicant = "";
      this.formToSendApplicant.address1OcupationApplicant = "";
      this.formToSendApplicant.address2OcupationApplicant = "";
      this.formToSendApplicant.cityOcupationApplicant = "";
      this.formToSendApplicant.stateOcupationApplicant = "";
      this.formToSendApplicant.zipOcupationApplicant = "";
      this.formToSendApplicant.workPhoneApplicant = "";
      this.formToSendApplicant.yearsTimeOnJobApplicant = "";
      this.formToSendApplicant.monthsTimeOnJobApplicant = "";
      this.formToSendApplicant.firstNameCoBuyer = "";
      this.formToSendApplicant.middleNameCoBuyer = "";
      this.formToSendApplicant.lastNameCoBuyer = "";
      this.formToSendApplicant.address1CoBuyer = "";
      this.formToSendApplicant.address2CoBuyer = "";
      this.formToSendApplicant.cityCoBuyer = "";
      this.formToSendApplicant.stateCoBuyer = "";
      this.formToSendApplicant.zipCoBuyer = "";
      this.formToSendApplicant.socialSecurityCoBuyer = "";
      this.formToSendApplicant.dateOfBirthCoBuyer = "";
      this.formToSendApplicant.driverLicenseNumberCoBuyer = "";
      this.formToSendApplicant.driverLicenseStateCoBuyer = "";
      this.formToSendApplicant.driverLicenseExpCoBuyer = "";
      this.formToSendApplicant.mobilePhoneCoBuyer = "";
      this.formToSendApplicant.homePhoneCoBuyer = "";
      this.formToSendApplicant.emailCoBuyer = "";
      this.formToSendApplicant.yearsTimeAtResidenceCoBuyer = "";
      this.formToSendApplicant.monthsTimeAtResidenceCoBuyer = "";
      this.formToSendApplicant.typeResidenceCoBuyer = "";
      this.formToSendApplicant.rentMortgageCoBuyer = "";
      this.formToSendApplicant.employerCoBuyer = "";
      this.formToSendApplicant.monthlyIncomeCoBuyer = "";
      this.formToSendApplicant.ocupationCoBuyer = "";
      this.formToSendApplicant.address1OcupationCoBuyer = "";
      this.formToSendApplicant.address2OcupationCoBuyer = "";
      this.formToSendApplicant.cityOcupationCoBuyer = "";
      this.formToSendApplicant.stateOcupationCoBuyer = "";
      this.formToSendApplicant.zipOcupationCoBuyer = "";
      this.formToSendApplicant.workPhoneCoBuyer = "";
      this.formToSendApplicant.yearsTimeOnJobCoBuyer = "";
      this.formToSendApplicant.monthsTimeOnJobCoBuyer = "";
      this.formToSendApplicant.comments = "";
    },
    activeCollapse() {
      this.resetForm();
      this.activeButtonCollapse = !this.activeButtonCollapse;
      setTimeout(function () {
        var cols2 = document.getElementsByClassName("vs-input");
        for (let i = 0; i < cols2.length; i++) {
          cols2[i].style["color"] = "black";
          cols2[i].style["background"] = "white";
          cols2[i].style.width = "99%";
        }
        var cols = document.getElementsByClassName("vs-select__input");
        for (let i = 0; i < cols.length; i++) {
          cols[i].style["color"] = "black";
          cols[i].style["background"] = "white";
          cols[i].style["min-height"] = "30%";
        }
      }, 300);
    },
    activeCollapseCoBuyer() {
      this.activeButtonCollapseCoBuyer = !this.activeButtonCollapseCoBuyer;
      this.countButtonCollapse += 1;
      if (this.activeButtonCollapseCoBuyer == false) {
        this.formToSendApplicant.firstNameCoBuyer = "";
        this.formToSendApplicant.middleNameCoBuyer = "";
        this.formToSendApplicant.lastNameCoBuyer = "";
        this.formToSendApplicant.address1CoBuyer = "";
        this.formToSendApplicant.address2CoBuyer = "";
        this.formToSendApplicant.cityCoBuyer = "";
        this.formToSendApplicant.stateCoBuyer = "";
        this.formToSendApplicant.zipCoBuyer = "";
        this.formToSendApplicant.socialSecurityCoBuyer = "";
        this.formToSendApplicant.dateOfBirthCoBuyer = "";
        this.formToSendApplicant.driverLicenseNumberCoBuyer = "";
        this.formToSendApplicant.driverLicenseStateCoBuyer = "";
        this.formToSendApplicant.driverLicenseExpCoBuyer = "";
        this.formToSendApplicant.mobilePhoneCoBuyer = "";
        this.formToSendApplicant.homePhoneCoBuyer = "";
        this.formToSendApplicant.emailCoBuyer = "";
        this.formToSendApplicant.yearsTimeAtResidenceCoBuyer = "";
        this.formToSendApplicant.monthsTimeAtResidenceCoBuyer = "";
        this.formToSendApplicant.typeResidenceCoBuyer = "";
        this.formToSendApplicant.rentMortgageCoBuyer = "";
        this.formToSendApplicant.employerCoBuyer = "";
        this.formToSendApplicant.monthlyIncomeCoBuyer = "";
        this.formToSendApplicant.ocupationCoBuyer = "";
        this.formToSendApplicant.address1OcupationCoBuyer = "";
        this.formToSendApplicant.address2OcupationCoBuyer = "";
        this.formToSendApplicant.cityOcupationCoBuyer = "";
        this.formToSendApplicant.stateOcupationCoBuyer = "";
        this.formToSendApplicant.zipOcupationCoBuyer = "";
        this.formToSendApplicant.workPhoneCoBuyer = "";
        this.formToSendApplicant.yearsTimeOnJobCoBuyer = "";
        this.formToSendApplicant.monthsTimeOnJobCoBuyer = "";
      }
      setTimeout(function () {
        var cols2 = document.getElementsByClassName("vs-input");
        for (let i = 0; i < cols2.length; i++) {
          cols2[i].style["color"] = "black";
          cols2[i].style["background"] = "white";
          cols2[i].style.width = "99%";
        }
        var cols = document.getElementsByClassName("vs-select__input");
        for (let i = 0; i < cols.length; i++) {
          cols[i].style["color"] = "black";
          cols[i].style["background"] = "white";
          cols[i].style["min-height"] = "30%";
        }
      }, 300);
    },
    infoCatalogBestCar() {
      this.activeButtonCollapseCoBuyer = false;
      this.activeButtonCollapse = false;
      this.activeDialogInfoCar = !this.activeDialogInfoCar;
      setTimeout(function () {
        var cols = document.getElementsByClassName("vs-dialog__content");
        for (let i = 0; i < cols.length; i++) {
          cols[i].style["max-height"] = "93%";
        }
      }, 300);
    },
    infoCatalog(data) {
      this.activeButtonCollapseCoBuyer = false;
      this.activeButtonCollapse = false;
      this.carSelected = data;
      this.activeDialogInfoCar = !this.activeDialogInfoCar;
      setTimeout(function () {
        var cols = document.getElementsByClassName("vs-dialog__content");
        for (let i = 0; i < cols.length; i++) {
          cols[i].style["max-height"] = "93%";
        }
      }, 200);
    },
    showImageInRoute(url) {
      window.open(url);
    },
    changeValueSearch(ev) {
      this.carsCatalogCopy = filterByValue(this.carsCatalog, ev.target.value);
    },
    handleChangeSlideCar(car) {
      this.carSelected = car;
      this.carInfoSelectedInSlide = {
        carName: car.carName,
        brandType: car.brandType,
      };
    },
    showAllCatalog() {
      const loading = this.$vs.loading({
        text: "Loading...",
        type: "corners",
        opacity: 1,
      });
      let globalThis = this;
      services
        .get("show_catalog")
        .then((res) => {
          globalThis.carsCatalog = res.data.data;
          globalThis.carsCatalogCopy = res.data.data;
        })
        .catch(() => {
          console.log("Error in show_catalog");
        })
        .finally(() => {
          loading.close();
          let count = 1;
          this.carsCatalog.forEach((item) => {
            if (count < 6) {
              if (count == 1) {
                this.newCarsAdded.push({
                  image: this.url_image + "static/" + item.photos[0],
                  brandType: item.brand,
                  carName: item.name,
                  class: "checkbox frst",
                  id: "slide-1",
                  nameSlider: "slider",
                  for: "slide-1",
                  checked: true,
                  brand: item.brand,
                  description: item.description,
                  name: item.name,
                  photos: item.photos,
                });

                this.carSelected = {
                  image: this.url_image + "static/" + item.photos[0],
                  brandType: item.brand,
                  carName: item.name,
                  class: "checkbox frst",
                  id: "slide-1",
                  nameSlider: "slider",
                  for: "slide-1",
                  checked: true,
                  brand: item.brand,
                  description: item.description,
                  name: item.name,
                  photos: item.photos,
                  salesPrice: item.salesPrice,
                };
              }
              if (count == 2) {
                this.newCarsAdded.push({
                  image: this.url_image + "static/" + item.photos[0],
                  brandType: item.brand,
                  carName: item.name,
                  class: "checkbox scnd",
                  id: "slider-2",
                  nameSlider: "slider",
                  for: "slider-2",
                  checked: false,
                  brand: item.brand,
                  description: item.description,
                  name: item.name,
                  photos: item.photos,
                });
              }
              if (count == 3) {
                this.newCarsAdded.push({
                  image: this.url_image + "static/" + item.photos[0],
                  brandType: item.brand,
                  carName: item.name,
                  class: "checkbox thrd",
                  id: "slider-3",
                  nameSlider: "slider",
                  for: "slider-3",
                  checked: false,
                  brand: item.brand,
                  description: item.description,
                  name: item.name,
                  photos: item.photos,
                });
              }
              if (count == 4) {
                this.newCarsAdded.push({
                  image: this.url_image + "static/" + item.photos[0],
                  brandType: item.brand,
                  carName: item.name,
                  class: "checkbox foth",
                  id: "slider-4",
                  nameSlider: "slider",
                  for: "slider-4",
                  checked: false,
                  brand: item.brand,
                  description: item.description,
                  name: item.name,
                  photos: item.photos,
                });
              }
            }
            count += 1;
          });
          this.carInfoSelectedInSlide =
            this.newCarsAdded.length > 0
              ? {
                carName: this.newCarsAdded[0].carName,
                brandType: this.newCarsAdded[0].brandType,
              }
              : null;
        });
    },
  },
  mounted() {
    this.showAllCatalog();

    var cols = document.getElementsByClassName("vs-input");
    for (let i = 0; i < cols.length; i++) {
      cols[i].style.width = "100%";
    }
  },
};
</script>

<style>
@import "../assets/styles/App.css";
@import "../assets/styles/Slide.css";

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slider {
  /*   background: white;
box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
  height: 150px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slider::before,
.slider::after {
  /*   background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); */
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.slider .slide {
  height: 100px;
  width: 200px;
  padding-left: 20px;
}

div.ex3 {
  width: 100%;
  height: 600px;
  overflow: auto;
}

.bodyColorHome {
  --body-color: hsl(219, 4%, 4%);
  --container-color: hsl(219, 4%, 7%);
  --title-color: hsl(219, 8%, 95%);
  --text-color: hsl(219, 8%, 75%);
  --text-color-light: hsl(219, 4%, 55%);
}

.rounded-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 20%;
  vertical-align: middle;
  border: 1px solid white;
  background-color: white;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.rounded-checkbox:checked {
  appearance: auto;
  cursor: pointer;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
</style>
